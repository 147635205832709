import {
  DATE,
  HEADER_TITLE,
  MANAGER_MEMO,
  NAME,
  NUMBER,
  PAYMENT_STATE,
  PRICE,
  STRING,
} from "../data_type/data_type";
import {
  w_px107rate,
  w_px108rate,
  w_px116rate,
  w_px121rate,
  w_px123rate,
  w_px128rate,
  w_px129rate,
  w_px143rate,
  w_px144rate,
  w_px16rate,
  w_px184rate,
  w_px36d9rate,
  w_px52rate,
  w_px53rate,
  w_px54rate,
  w_px66rate,
  w_px71rate,
  w_px76rate,
  w_px85rate,
  w_px89d7rate,
  w_px96rate,
  w_px97rate,
} from "../../design_system/size";

export const store_payment_structureInfo = [
  {
    id: 0,
    close_width: w_px128rate,
    open_width: w_px96rate,
  },
  {
    id: 1,
    close_width: w_px128rate,
    open_width: w_px96rate,
  },
  {
    id: 2,
    close_width: w_px129rate,
    open_width: w_px97rate,
  },
  {
    id: 3,
    close_width: w_px129rate,
    open_width: w_px97rate,
  },
  {
    id: 4,
    close_width: w_px129rate,
    open_width: w_px97rate,
  },
  {
    id: 5,
    close_width: w_px129rate,
    open_width: w_px97rate,
  },
  {
    id: 6,
    close_width: w_px129rate,
    open_width: w_px97rate,
  },
  {
    id: 7,
    close_width: w_px129rate,
    open_width: w_px97rate,
  },
  {
    id: 8,
    close_width: w_px129rate,
    open_width: w_px97rate,
  },
  {
    id: 9,
    close_width: w_px129rate,
    open_width: w_px97rate,
  },
];

export const store_payment_headerTitle = [
  {
    type: HEADER_TITLE,
    data: "이름",
  },
  {
    type: HEADER_TITLE,
    data: "이메일 주소",
  },
  {
    type: HEADER_TITLE,
    data: "휴대폰 번호",
  },
  {
    type: HEADER_TITLE,
    data: "숙소 개수",
  },
  {
    type: HEADER_TITLE,
    data: "은행명",
  },
  {
    type: HEADER_TITLE,
    data: "은행 계좌 번호",
  },
  {
    type: HEADER_TITLE,
    data: "은행 계좌주명",
  },
  {
    type: HEADER_TITLE,
    data: "전월 매출",
  },
  {
    type: HEADER_TITLE,
    data: "당월 매출",
  },
  {
    type: HEADER_TITLE,
    data: "총 누적 매출",
  },
];

export const store_payment_bodyContents = [
  {
    id: 0,
    data: [
      {
        type: NAME,
        data: "자몽",
      },
      {
        type: STRING,
        data: "tjdwls452@naver.com",
      },
      {
        type: STRING,
        data: "010-1111-2222",
      },
      {
        type: NUMBER,
        data: 2,
      },
      {
        type: PRICE,
        data: 48000,
      },
      {
        type: PRICE,
        data: 48000,
      },
      {
        type: PRICE,
        data: 48000,
      },
      {
        type: PRICE,
        data: 48000,
      },
    ],
  },
];

import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import {
  h_px6rate,
  w_px11rate,
  w_px160rate,
  w_px28rate,
  w_px8rate,
} from "../../design_system/size";
import {
  rgba_66_139_247_0d2,
  rgb_0_0_0,
  rgb_255_255_255,
  rgb_66_139_247,
} from "../../design_system/colors";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../data_system/contexts/auth_context/auth_context";

function Login() {
  let navigate = useNavigate();
  const { handleLogin, logout } = useAuth();

  const [inputId, setInputId] = useState("");
  const [inputPw, setInputPw] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleInputId = (e) => {
    setInputId(e.target.value);
  };

  const handleInputPw = (e) => {
    setInputPw(e.target.value);
  };

  async function onClickLogin() {
    try {
      const get = await handleLogin(inputId, inputPw);
      if (get.status === 200) {
        navigate("/after-login/user");
      }
    } catch (error) {
      console.log(error);
      alert("로그인 또는 비밀번호가 틀립니다.");
    }
  }

  useEffect(() => {
    logout();
  }, []);

  return loading ? (
    <div>Loading...</div>
  ) : (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "block",
        }}
      >
        <Input placeholder="아이디" value={inputId} onChange={handleInputId} />
        <Input
          type="password"
          placeholder="비밀번호"
          value={inputPw}
          onChange={handleInputPw}
        />
        <Btn type="button" onClick={onClickLogin}>
          로그인
        </Btn>
      </div>
    </div>
  );
}

export default React.memo(Login);

const Input = styled.input`
  display: block;
  padding-top: ${h_px6rate}px;
  padding-bottom: ${h_px6rate}px;
  padding-left: ${w_px8rate}px;
  padding-right: ${w_px8rate}px;
  width: ${w_px160rate}px;
  height: ${w_px28rate}px;
  border: 1px solid ${rgba_66_139_247_0d2};
  font-size: ${w_px11rate}px;
  color: ${rgb_0_0_0};
  font-family: noto_sans_kr_medium;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
`;

const Btn = styled.button`
  width: ${w_px160rate}px;
  height: ${w_px28rate}px;
  border-width: 0px;
  background-color: ${rgb_66_139_247};
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_bold;
  color: ${rgb_255_255_255};
`;

import styled from "styled-components";
import React from "react";
import { h_px36rate, w_px12rate, w_px145rate } from "../../size";
import { rgb_255_16_125, rgb_255_255_255 } from "../../colors";

const PinkBtn = ({ children, onClick }) => {
  return <Btn onClick={onClick}>{children}</Btn>;
};

export default React.memo(PinkBtn);

const Btn = styled.button`
  width: ${w_px145rate}px;
  height: ${h_px36rate}px;
  border: 1px solid ${rgb_255_16_125};
  background-color: ${rgb_255_16_125};
  align-items: center;
  justify-content: center;
  font-size: ${w_px12rate}px;
  font-family: noto_sans_kr_bold;
  color: ${rgb_255_255_255};
`;

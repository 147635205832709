import React from "react";
import styled from "styled-components";
import { w_px12rate } from "../../size";

const SubTitle = ({ children }) => {
  return <Title>{children}</Title>;
};

export default React.memo(SubTitle);

const Title = styled.div`
  font-size: ${w_px12rate}px;
  font-family: noto_sans_kr_medium;
`;

import React, { useEffect, useState, useCallback, useContext } from "react";
import AdminTable from "../../design_system/components/admin_table/AdminTable";
import {
  user_headerTitle,
  user_structureInfo,
} from "../../data_system/user_data/user_data";
import { USER_DETAIL_COMPONENT } from "../../data_system/detail_data_type/detail_data_type";
import { DETAIL_COMMON_FORM } from "../../design_system/components/admin_table/detail/forms/detail_form_type/detail_form_type";
import { NONE_DETAIL_REGISTER } from "../../data_system/register_data_type/register_data_type";
import {
  getAllUsersForExcel,
  getUsersByPageWithCondition,
} from "../../communication_system/axios_apis/axios_apis";
import {
  DATE,
  MANAGER_MEMO,
  NAME,
  NUMBER,
  PRICE,
  STRING,
  SUBSCRIBE_STATE,
} from "../../data_system/data_type/data_type";
import styled from "styled-components";
import Dropdown from "../../design_system/components/dropdown/Dropdown";
import SearchBar from "../../design_system/components/searchbar/SearchBar";
import {
  h_px17rate,
  h_px22rate,
  w_px10rate,
  w_px12rate,
  w_px4rate,
  w_px5rate,
} from "../../design_system/size";
import { rgb_35_40_45, rgb_66_139_247 } from "../../design_system/colors";
import LongDropdown from "../../design_system/components/long_dropdown/LongDropdown";
import { BodyContentsContext } from "../../data_system/contexts/body_contents_context/body_contents_context";
import moment from "moment";
import { table_date_function } from "../../data_system/data_functions/table_date_function/table_date_function";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const User = () => {
  const { bodyContents, setBodyContents } = useContext(BodyContentsContext);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [totalUsersNumber, setTotalUsersNumber] = useState(0);
  const [loading, setLoading] = useState(true);

  const [choiceRowIndex, setChoiceRowIndex] = useState(null);
  const [itemOpen, setItemOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);

  const [userStateDropdownItem, setUserStateDropdownItem] = useState({
    id: 0,
    title: "전체 회원",
    selected: false,
    key: "total_user",
    value: 1,
  });

  const [userDetailDropdownItem, setUserDetailDropdownItem] = useState({
    id: 0,
    title: "휴대폰번호",
    selected: false,
    key: "phone_number",
    value: 1,
  });

  const [userStateList, setUserStateList] = useState([
    {
      id: 0,
      title: "전체 회원",
      selected: false,
      key: "total_users",
      value: 1,
    },
    {
      id: 1,
      title: "베이직",
      selected: false,
      key: "basic",
      value: 2,
    },
    {
      id: 2,
      title: "스탠다드",
      selected: false,
      key: "standard",
      value: 3,
    },
    {
      id: 3,
      title: "이용 안 함",
      selected: false,
      key: "not_use",
      value: 4,
    },
  ]);

  const [userDetailList, setUserDetailList] = useState([
    {
      id: 0,
      title: "이름",
      selected: false,
      key: "name",
      value: 1,
    },
    {
      id: 1,
      title: "이메일 주소",
      selected: false,
      key: "email",
      value: 2,
    },
    {
      id: 2,
      title: "휴대폰 번호",
      selected: false,
      key: "phone_number",
      value: 3,
    },
  ]);

  const [page, setPage] = useState(1);

  const userStateResetThenSet = useCallback((item) => {
    setUserStateDropdownItem(item);
  }, []);

  const userDetailResetThenSet = useCallback((item) => {
    setUserDetailDropdownItem(item);
  }, []);

  const onChangeSearchText = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const onSearch = useCallback(async () => {
    const response = await getUsersByPageWithCondition(
      page,
      userStateDropdownItem.value,
      userDetailDropdownItem.value,
      searchText
    );
    console.log(response);
    let tempBodyContents = [];
    for (let i = 0; i < response.data.users.length; i++) {
      const totalSubscriptionEndDate =
        response.data.users[i].totalSubscriptionEndDate;
      const endDate = moment.utc(totalSubscriptionEndDate).clone().toDate();
      const now = moment.utc().clone().add(9, "hours").toDate();

      console.log(response.data.users[0].createdAt);
      tempBodyContents.push({
        id: response.data.users[i].id,
        data: [
          {
            type: DATE,
            data: response.data.users[i].createdAt,
          },
          {
            type: NAME,
            data: response.data.users[i].name,
          },
          {
            type: STRING,
            data: response.data.users[i].email,
          },
          {
            type: STRING,
            data: response.data.users[i].phoneNumber,
          },
          {
            type: NUMBER,
            data: response.data.users[i].maxGuideNumber,
          },
          {
            type: PRICE,
            data: response.data.users[i].subscriptionPrice,
          },
          {
            type: STRING,
            data:
              endDate < now
                ? "이용 안 함"
                : response.data.users[i].productId === 1 ||
                  response.data.users[i].productId === 3
                ? "스탠다드"
                : "베이직",
          },
          {
            type: MANAGER_MEMO,
            data: response.data.users[i].adminMemo,
          },
        ],
      });
    }
    setBodyContents(tempBodyContents);
    setTotalPages(response.data.totalPages);
    setTotalUsersNumber(response.data.totalUsersNumber);
  }, [
    page,
    userStateDropdownItem.value,
    userDetailDropdownItem.value,
    searchText,
  ]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await getUsersByPageWithCondition(
        page,
        userStateDropdownItem.value,
        userDetailDropdownItem.value,
        searchText
      );
      console.log(response);
      let tempBodyContents = [];
      for (let i = 0; i < response.data.users.length; i++) {
        const totalSubscriptionEndDate =
          response.data.users[i].totalSubscriptionEndDate;
        const endDate = moment.utc(totalSubscriptionEndDate).clone().toDate();
        const now = moment.utc().clone().add(9, "hours").toDate();

        tempBodyContents.push({
          id: response.data.users[i].id,
          data: [
            {
              type: DATE,
              data: response.data.users[i].createdAt,
            },
            {
              type: NAME,
              data: response.data.users[i].name,
            },
            {
              type: STRING,
              data: response.data.users[i].email,
            },
            {
              type: STRING,
              data: response.data.users[i].phoneNumber,
            },
            {
              type: NUMBER,
              data: response.data.users[i].maxGuideNumber,
            },
            {
              type: PRICE,
              data: response.data.users[i].subscriptionPrice,
            },
            {
              type: STRING,
              data:
                endDate < now
                  ? "이용 안 함"
                  : response.data.users[i].productId === 1 ||
                    response.data.users[i].productId === 3
                  ? "스탠다드"
                  : "베이직",
            },
            {
              type: MANAGER_MEMO,
              data: response.data.users[i].adminMemo,
            },
          ],
        });
      }
      setBodyContents(tempBodyContents);
      setTotalPages(response.data.totalPages);
      setTotalUsersNumber(response.data.totalUsersNumber);
      setLoading(false);
    }

    fetchData();
  }, [page, userStateDropdownItem.value]);

  if (loading) return <div>loading...</div>;

  return (
    <>
      <>
        <SearchSection>
          <Dropdown
            list={userStateList}
            resetThenSet={userStateResetThenSet}
            index={0}
          ></Dropdown>
          <div
            style={{
              marginLeft: w_px4rate,
            }}
          >
            <LongDropdown
              list={userDetailList}
              resetThenSet={userDetailResetThenSet}
              index={0}
            ></LongDropdown>
          </div>
          <div
            style={{
              marginLeft: w_px4rate,
            }}
          >
            <SearchBar
              searchText={searchText}
              onChangeSearchText={onChangeSearchText}
              onSearch={onSearch}
            ></SearchBar>
          </div>
        </SearchSection>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: w_px10rate,
            marginBottom: h_px17rate,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <MiddleSectionBlackText>전체</MiddleSectionBlackText>
            <div
              style={{
                marginLeft: w_px5rate,
                display: "flex",
                alignItems: "center",
              }}
            >
              <MiddleSectionBlueText>{totalUsersNumber}</MiddleSectionBlueText>
              <MiddleSectionBlackText>명</MiddleSectionBlackText>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: w_px10rate,
            marginBottom: h_px17rate,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontFamily: "noto_sans_kr_bold",
                cursor: "pointer",
                fontSize: w_px12rate,
                border: "1px solid green",
                padding: "8px",
                borderRadius: "8px",
              }}
              onClick={async () => {
                const response = await getAllUsersForExcel();

                console.log(response.data.users.users);

                const customData = response.data.users.users.map(
                  (row, index) => [
                    index + 1,
                    table_date_function(row.createdAt),
                    row.name,
                    row.email,
                    row.phoneNumber,
                    row.maxGuideNumber,
                    row.subscriptionPrice,
                    row.productId === 1 || row.productId === 3
                      ? "스탠다드"
                      : "베이직",
                  ]
                );

                // 첫 번째 행에 열 제목을 추가
                const worksheet = XLSX.utils.aoa_to_sheet([
                  [
                    "No.",
                    "가입 일시",
                    "이름",
                    "이메일 주소",
                    "휴대폰 번호",
                    "숙소 개수",
                    "월 결제금액",
                    "구독 상태",
                  ],
                ]);
                XLSX.utils.sheet_add_aoa(worksheet, customData, { origin: -1 });

                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

                // 열 너비 설정 (선택 사항)
                const colWidths = [
                  { wpx: 50 },
                  { wpx: 150 },
                  { wpx: 100 },
                  { wpx: 100 },
                  { wpx: 100 },
                  { wpx: 50 },
                  { wpx: 50 },
                  { wpx: 50 },
                ];
                worksheet["!cols"] = colWidths;

                const excelBuffer = XLSX.write(workbook, {
                  bookType: "xlsx",
                  type: "array",
                });
                const blob = new Blob([excelBuffer], {
                  type: "application/octet-stream",
                });
                saveAs(blob, "users.xlsx");
              }}
            >
              엑셀 다운로드
            </div>
          </div>
        </div>
      </>
      <AdminTable
        structureInfo={user_structureInfo}
        headerTitle={user_headerTitle}
        bodyContents={bodyContents}
        DETAIL_DATA_TYPE={USER_DETAIL_COMPONENT}
        DETAIL_FORM_TYPE={DETAIL_COMMON_FORM}
        DETAIL_DATA_REGISTER={NONE_DETAIL_REGISTER}
        detail_disable={false}
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        setBodyContents={setBodyContents}
        choiceRowIndex={choiceRowIndex}
        setChoiceRowIndex={setChoiceRowIndex}
        itemOpen={itemOpen}
        setItemOpen={setItemOpen}
        registerOpen={registerOpen}
        setRegisterOpen={setRegisterOpen}
      ></AdminTable>
    </>
  );
};

export default React.memo(User);

const SearchSection = styled.div`
  position: relative;
  display: flex;
  margin-bottom: ${h_px22rate}px;
  z-index: 999;
`;

const MiddleSectionBlackText = styled.div`
  font-size: ${w_px12rate}px;
  color: ${rgb_35_40_45};
  font-family: noto_sans_kr_bold;
`;

const MiddleSectionBlueText = styled.div`
  font-size: ${w_px12rate}px;
  color: ${rgb_66_139_247};
  font-family: noto_sans_kr_bold;
`;

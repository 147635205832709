import { useCallback, useContext, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import AddScheduleModalContext from "../../../data_system/contexts/modal_context/add_schedule_modal_context";
import {
  rgba_0_0_0_0d1,
  rgba_66_139_247_0d2,
  rgb_0_0_0,
  rgb_255_255_255,
  rgb_66_139_247,
} from "../../colors";
import { XMarkGray1x } from "../../icons/icons";
import {
  h_px14rate,
  h_px262rate,
  h_px28rate,
  h_px6rate,
  w_px11rate,
  w_px191rate,
  w_px20rate,
  w_px26rate,
  w_px3rate,
  w_px477rate,
  w_px48rate,
  w_px49rate,
  w_px6rate,
  w_px82rate,
  w_px86rate,
} from "../../size";
import CheckBox from "../check_box/CheckBox";
import DetailTextInput from "../detail_text_input/DetailTextInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import { only_number } from "../../../data_system/validation_check/validation_check";

function AddScheduleModal() {
  const { add_schedule_modal_state, add_schedule_modal_actions } = useContext(
    AddScheduleModalContext
  );

  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState("");
  const onChangeStartTime = useCallback((e) => {
    if (e.target.value.length <= 5) {
      if (only_number.test(e.target.value)) {
        if (e.target.value.length === 4) {
          setStartTime(
            e.target.value.slice(0, 2) + ":" + e.target.value.slice(2)
          );
        } else {
          setStartTime(e.target.value);
        }
      } else {
        if (only_number.test(e.target.value.split(":").join(""))) {
          setStartTime(e.target.value.split(":").join(""));
        }
      }
    }
  }, []);
  const [endTime, setEndTime] = useState("");
  const onChangeEndTime = useCallback((e) => {
    if (e.target.value.length <= 5) {
      if (only_number.test(e.target.value)) {
        if (e.target.value.length === 4) {
          setEndTime(
            e.target.value.slice(0, 2) + ":" + e.target.value.slice(2)
          );
        } else {
          setEndTime(e.target.value);
        }
      } else {
        if (only_number.test(e.target.value.split(":").join(""))) {
          setEndTime(e.target.value.split(":").join(""));
        }
      }
    }
  }, []);
  const [joinNumber, setJoinNumber] = useState("");
  const onChangeJoinNumber = useCallback((e) => {
    if (only_number.test(e.target.value)) {
      setJoinNumber(e.target.value);
    }
  }, []);
  const [adjustSchedule, setAdjustSchedule] = useState(false);
  const onClickAdjustSchedule = useCallback(() => {
    setAdjustSchedule((pre) => !pre);
  }, []);
  const [infinite, setInfinite] = useState(false);
  const onClickInfinite = useCallback(() => {
    setInfinite((pre) => !pre);
  }, []);

  return createPortal(
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: rgba_0_0_0_0d1,
        zIndex: 999,
      }}
    >
      <div
        style={{
          width: w_px477rate,
          height: h_px262rate,
          borderRadius: w_px20rate,
          backgroundColor: rgb_255_255_255,
          padding: w_px48rate,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TitleText>일정 추가</TitleText>
          <CancelBtn
            onClick={() => {
              add_schedule_modal_actions.setModalVisible(false);
            }}
          >
            <XMarkGray1x></XMarkGray1x>
          </CancelBtn>
        </div>
        <div
          style={{
            marginTop: h_px14rate,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <SubTitleText>시작일</SubTitleText>
            <div
              style={{
                marginTop: h_px6rate,
              }}
            >
              <DateBtn
                locale={ko}
                selected={date}
                dateFormat="yyyy-MM-dd"
                onChange={(date) => setDate(date)}
                placeholderText="클릭해주세요."
              ></DateBtn>
            </div>
          </div>
          <div
            style={{
              width: w_px191rate,
            }}
          >
            <SubTitleText>시간</SubTitleText>
            <div
              style={{
                marginTop: h_px6rate,
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  marginRight: w_px6rate,
                }}
              >
                <DetailTextInput
                  width={w_px86rate}
                  height={h_px28rate}
                  placeholder={"10:00"}
                  value={startTime}
                  onChange={onChangeStartTime}
                  disabled={adjustSchedule}
                ></DetailTextInput>
              </div>
              <MiddleTimeText>~</MiddleTimeText>
              <div
                style={{
                  marginLeft: w_px6rate,
                }}
              >
                <DetailTextInput
                  width={w_px86rate}
                  height={h_px28rate}
                  placeholder={"22:00"}
                  value={endTime}
                  onChange={onChangeEndTime}
                  disabled={adjustSchedule}
                ></DetailTextInput>
              </div>
            </div>
            <div
              style={{
                marginTop: h_px6rate,
                display: "flex",
                alignItems: "center",
              }}
            >
              <CheckBox
                checked={adjustSchedule}
                onClick={onClickAdjustSchedule}
              ></CheckBox>
              <div
                style={{
                  marginLeft: w_px6rate,
                }}
              >
                <ContentTitleText>일정 협의 필요</ContentTitleText>
              </div>
            </div>
          </div>
          <div
            style={{
              width: w_px86rate,
            }}
          >
            <SubTitleText>모집 인원</SubTitleText>
            <div
              style={{
                marginTop: h_px6rate,
              }}
            >
              <DetailTextInput
                width={w_px86rate}
                height={h_px28rate}
                placeholder={"직접입력"}
                value={joinNumber}
                onChange={onChangeJoinNumber}
                disabled={infinite}
              ></DetailTextInput>
            </div>
            <div
              style={{
                marginTop: h_px6rate,
                display: "flex",
                alignItems: "center",
              }}
            >
              <CheckBox checked={infinite} onClick={onClickInfinite}></CheckBox>
              <div
                style={{
                  marginLeft: w_px6rate,
                }}
              >
                <ContentTitleText>인원제한 없음</ContentTitleText>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: h_px14rate,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <AddBtn
            onClick={() => {
              console.log(new Date("2019-04-05T19:00:00.000Z"));
            }}
          >
            추가
          </AddBtn>
        </div>
      </div>
    </div>,
    document.getElementById("addScheduleModal")
  );
}

export default AddScheduleModal;

const CancelBtn = styled.button`
  border-width: 0px;
  margin: 0px;
  padding: 0px;
  background-color: ${rgb_255_255_255};
`;

const TitleText = styled.div`
  font-size: ${w_px26rate}px;
  font-family: noto_sans_kr_bold;
  color: ${rgb_66_139_247};
`;

const SubTitleText = styled.div`
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_medium;
  color: ${rgb_0_0_0};
  padding: 0px;
`;

const DateBtn = styled(DatePicker)`
  padding-left: ${w_px3rate}px;
  padding-right: ${w_px3rate}px;
  margin: 0px;
  width: ${w_px82rate}px;
  height: ${h_px28rate}px;
  box-sizing: border-box;
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_regular;
  color: ${rgb_0_0_0};
  border: 1px solid ${rgba_66_139_247_0d2};
  background-color: ${rgb_255_255_255};
  display: flex;
  align-items: center;
`;

const MiddleTimeText = styled.div`
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_regular;
  color: ${rgb_66_139_247};
`;
const ContentTitleText = styled.div`
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_regular;
  color: ${rgb_0_0_0};
`;

const AddBtn = styled.button`
  border-width: 0px;
  padding: 0px;
  margin: 0px;
  background-color: ${rgb_66_139_247};
  color: ${rgb_255_255_255};
  width: ${w_px49rate}px;
  height: ${h_px28rate}px;
`;

const SelectDate = styled(DatePicker)`
  height: 22px;
  padding: 6px 12px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #e5e5e5;
  outline: none;
  cursor: pointer;
`;

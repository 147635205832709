import React from "react";
import styled from "styled-components";
import {
  rgba_66_139_247_0d2,
  rgb_171_171_171,
  rgb_66_139_247,
} from "../../colors";
import {
  h_px2rate,
  h_px38rate,
  w_px12rate,
  w_px15rate,
  w_px236rate,
} from "../../size";
import PropTypes from "prop-types";
import { SearchWhite1x } from "../../icons/icons";

const SearchBar = ({ searchText, onChangeSearchText, onSearch }) => {
  return (
    <SearchBarFrame>
      <SearchBarInput
        placeholder="검색어 입력"
        value={searchText}
        onChange={onChangeSearchText}
      ></SearchBarInput>
      <SearchBtn onClick={onSearch}>
        <div
          style={{
            marginTop: h_px2rate,
          }}
        >
          <SearchWhite1x></SearchWhite1x>
        </div>
      </SearchBtn>
    </SearchBarFrame>
  );
};

SearchBar.propTypes = {
  searchText: PropTypes.string.isRequired,
  onChangeSearchText: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default React.memo(SearchBar);

const SearchBarFrame = styled.div`
  display: flex;
`;

const SearchBarInput = styled.input`
  width: ${w_px236rate}px;
  height: ${h_px38rate}px;
  padding-left: ${w_px15rate}px;
  padding-right: ${w_px15rate}px;
  vertical-align: middle;
  margin: 0px;
  font-size: ${w_px12rate}px;
  font-family: noto_sans_kr_regular;
  box-sizing: border-box;
  border: 1px solid ${rgba_66_139_247_0d2};
  border-right-width: 0px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${rgb_171_171_171};
  }
  :-ms-input-placeholder {
    color: ${rgb_171_171_171};
  }
  &:focus {
    outline: none;
  }
`;

const SearchBtn = styled.button`
  width: ${h_px38rate}px;
  height: ${h_px38rate}px;
  background-color: ${rgb_66_139_247};
  border-width: 0px;
  align-items: center;
  justify-content: center;
`;

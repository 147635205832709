import React, { useState, useEffect } from "react";
import AdministratorDetailComponent from "./AdministratorDetailComponent";
import { getAdministratorById } from "../../../../../../communication_system/axios_apis/axios_apis";

const AdministratorDetailComponentContainer = ({
  choiceRowIndex,
  page,
  limit,
  item,
  setBodyContents,
}) => {
  const [administratorDetail, setAdministratorDetail] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await getAdministratorById(item.id);

      console.log(response.data.administrator);
      setAdministratorDetail(response.data.administrator);
      setLoading(false);
    }

    fetchData();
  }, [item]);

  if (loading) return <div>loading...</div>;

  return (
    <AdministratorDetailComponent
      administratorDetail={administratorDetail}
      item={item}
      setBodyContents={setBodyContents}
    ></AdministratorDetailComponent>
  );
};

export default React.memo(AdministratorDetailComponentContainer);

import React from "react";
import styled from "styled-components";
import {
  h_px4rate,
  h_px64rate,
  w_px20rate,
  w_px362rate,
} from "../../../../../size";
import { rgb_255_255_255, rgb_66_139_247 } from "../../../../../colors";
import { ArrowBackwardWhite1x } from "../../../../../icons/icons";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import TotalDetailComponents from "../../detail_components/total_detail_component/TotalDetailComponents";
import TotalDetailRegister from "../../detail_registers/total_detail_register/TotalDetailRegister";
import { ADMINISTRATOR_DETAIL_REGISTER } from "../../../../../../data_system/register_data_type/register_data_type";
import BodyScroll from "../../../../body_scroll/BodyScroll";

const DetailCommonForm = ({
  setItemOpen,
  choiceRowIndex,
  setChoiceRowIndex,
  registerOpen,
  setRegisterOpen,
  DETAIL_DATA_TYPE,
  DETAIL_DATA_REGISTER,
  limit,
  page,
  item,
  setBodyContents,
}) => {
  const [loading, setLoading] = useState(true);

  const [detailTitle, setDetailTitle] = useState("상세정보");

  useEffect(() => {
    setLoading(true);
    if (registerOpen) {
      if (DETAIL_DATA_REGISTER === ADMINISTRATOR_DETAIL_REGISTER) {
        setDetailTitle("관리자정보 등록");
      }
    } else {
      setDetailTitle("상세정보");
    }
    setLoading(false);
  }, [registerOpen, DETAIL_DATA_REGISTER]);

  const close = useCallback(() => {
    setItemOpen(false);
    setRegisterOpen(false);
    setChoiceRowIndex(null);
  }, [setItemOpen, setRegisterOpen, setChoiceRowIndex]);

  return (
    !loading && (
      <div>
        <HeaderTitle>
          <HeaderArrowBackwardBtn
            onClick={() => {
              close();
            }}
          >
            <ArrowBackwardWhite1x></ArrowBackwardWhite1x>
          </HeaderArrowBackwardBtn>
          <div
            style={{
              marginBottom: h_px4rate,
            }}
          >
            {detailTitle}
          </div>
        </HeaderTitle>
        <BodyScroll>
          {!registerOpen ? (
            <TotalDetailComponents
              choiceRowIndex={choiceRowIndex}
              DETAIL_DATA_TYPE={DETAIL_DATA_TYPE}
              limit={limit}
              page={page}
              item={item}
              setBodyContents={setBodyContents}
            ></TotalDetailComponents>
          ) : (
            <TotalDetailRegister
              DETAIL_DATA_REGISTER={DETAIL_DATA_REGISTER}
              setBodyContents={setBodyContents}
            ></TotalDetailRegister>
          )}
        </BodyScroll>
      </div>
    )
  );
};

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  width: ${w_px362rate}px;
  height: ${h_px64rate}px;
  font-size: ${w_px20rate}px;
  font-family: noto_sans_kr_bold;
  background-color: ${rgb_66_139_247};
  color: ${rgb_255_255_255};
`;

const HeaderArrowBackwardBtn = styled.button`
  border-width: 0px;
  background-color: ${rgb_66_139_247};
`;

export default React.memo(DetailCommonForm);

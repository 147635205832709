import React, { useContext } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import AdminMemoModalContext from "../../../data_system/contexts/modal_context/admin_memo_modal_context";
import {
  STORE_PAYMENT_DETAIL_COMPONENT,
  ADMINISTRATOR_DETAIL_COMPONENT,
  NONE_DETAIL_COMPONENT,
  SUBSCRIBE_PAYMENT_DETAIL_COMPONENT,
  USER_DETAIL_COMPONENT,
} from "../../../data_system/detail_data_type/detail_data_type";
import { rgba_0_0_0_0d1, rgb_255_255_255, rgb_66_139_247 } from "../../colors";
import { XMarkGray1x } from "../../icons/icons";
import {
  h_px231rate,
  h_px439rate,
  w_px10rate,
  w_px12rate,
  w_px15rate,
  w_px18rate,
  w_px20rate,
  w_px26rate,
  w_px40rate,
  w_px500rate,
  w_px535rate,
} from "../../size";
import { putUserAdminMemoByAdmin } from "../../../communication_system/axios_apis/axios_apis";
import { BodyContentsContext } from "../../../data_system/contexts/body_contents_context/body_contents_context";

function AdminMemoModal() {
  const { admin_memo_modal_state, admin_memo_modal_actions } = useContext(
    AdminMemoModalContext
  );
  const { setBodyContents } = useContext(BodyContentsContext);

  return createPortal(
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: rgba_0_0_0_0d1,
        zIndex: 999,
      }}
    >
      <div
        style={{
          width: w_px535rate,
          height: h_px439rate,
          borderRadius: w_px20rate,
          backgroundColor: rgb_255_255_255,
          padding: w_px40rate,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: w_px18rate,
              height: w_px18rate,
            }}
          ></div>
          <TitleText>관리자 메모</TitleText>
          <CancelBtn
            onClick={() => {
              admin_memo_modal_actions.setModalVisible(false);
            }}
          >
            <XMarkGray1x></XMarkGray1x>
          </CancelBtn>
        </div>
        <div
          style={{
            marginTop: w_px40rate,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MemoInput
            value={admin_memo_modal_state.adminMemoMessage}
            onChange={(e) => {
              admin_memo_modal_actions.setAdminMemoMessage(e.target.value);
            }}
          ></MemoInput>
        </div>
        <div
          style={{
            marginTop: w_px40rate,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <UpdateBtn
            onClick={async () => {
              if (
                admin_memo_modal_state.DETAIL_DATA_TYPE ===
                USER_DETAIL_COMPONENT
              ) {
                const response = await putUserAdminMemoByAdmin(
                  admin_memo_modal_state.id,
                  {
                    adminMemo: admin_memo_modal_state.adminMemoMessage,
                  }
                );

                if (response.status === 200) {
                  setBodyContents((prevContents) => {
                    return prevContents.map((content) => {
                      if (content.id === admin_memo_modal_state.id) {
                        const updatedData = [...content.data];
                        updatedData[7] = {
                          ...updatedData[7],
                          data: admin_memo_modal_state.adminMemoMessage,
                        };

                        return { ...content, data: updatedData };
                      } else {
                        return content;
                      }
                    });
                  });
                  admin_memo_modal_actions.setModalVisible(false);
                }
              } else if (
                admin_memo_modal_state.DETAIL_DATA_TYPE ===
                ADMINISTRATOR_DETAIL_COMPONENT
              ) {
              } else if (
                admin_memo_modal_state.DETAIL_DATA_TYPE ===
                SUBSCRIBE_PAYMENT_DETAIL_COMPONENT
              ) {
              } else if (
                admin_memo_modal_state.DETAIL_DATA_TYPE ===
                STORE_PAYMENT_DETAIL_COMPONENT
              ) {
              } else if (
                admin_memo_modal_state.DETAIL_DATA_TYPE ===
                NONE_DETAIL_COMPONENT
              ) {
              }
            }}
          >
            수정 완료
          </UpdateBtn>
        </div>
      </div>
    </div>,
    document.getElementById("adminMemoModal")
  );
}

export default React.memo(AdminMemoModal);

const CancelBtn = styled.button`
  border-width: 0px;
  margin: 0px;
  padding: 0px;
  background-color: ${rgb_255_255_255};
`;

const TitleText = styled.div`
  font-size: ${w_px26rate}px;
  font-family: noto_sans_kr_bold;
  color: ${rgb_66_139_247};
`;

const MemoInput = styled.textarea`
  width: ${w_px500rate}px;
  height: ${h_px231rate}px;
  border: 1px solid ${rgb_66_139_247};
  border-radius: ${w_px15rate}px;
  padding: ${w_px20rate}px;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
`;

const UpdateBtn = styled.button`
  border-width: 0px;
  margin: 0px;
  padding-top: ${w_px10rate}px;
  padding-bottom: ${w_px10rate}px;
  padding-left: ${w_px20rate}px;
  padding-right: ${w_px20rate}px;
  background-color: ${rgb_66_139_247};
  color: ${rgb_255_255_255};
  font-size: ${w_px12rate}px;
  font-family: noto_sans_kr_bold;
`;

import { createContext, useState } from "react";

const DeleteModalContext = createContext({
  delete_modal_state: {
    modalVisible: false,
  },
  delete_modal_actions: {
    setModalVisible: () => {},
  },
});

const DeleteModalProvider = ({ children }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const value = {
    delete_modal_state: { modalVisible },
    delete_modal_actions: {
      setModalVisible,
    },
  };

  return (
    <DeleteModalContext.Provider value={value}>
      {children}
    </DeleteModalContext.Provider>
  );
};

const DeleteModalConsumer = DeleteModalContext.Consumer;

export { DeleteModalConsumer, DeleteModalProvider };

export default DeleteModalContext;

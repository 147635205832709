import React from "react";
import {
  ADMINISTRATOR_DETAIL_REGISTER,
  NONE_DETAIL_REGISTER,
} from "../../../../../../data_system/register_data_type/register_data_type";
import AdministratorDetailRegister from "../administrator_detail_register/AdministratorDetailRegister";

const TotalDetailRegister = ({ DETAIL_DATA_REGISTER, setBodyContents }) => {
  if (DETAIL_DATA_REGISTER === ADMINISTRATOR_DETAIL_REGISTER) {
    return (
      <AdministratorDetailRegister
        setBodyContents={setBodyContents}
      ></AdministratorDetailRegister>
    );
  } else if (DETAIL_DATA_REGISTER === NONE_DETAIL_REGISTER) {
    return <></>;
  }
};

export default React.memo(TotalDetailRegister);

import React, { useState, useEffect } from "react";
import SubscribePaymentDetailComponent from "./SubscribePaymentDetailComponent";
import { getSubscribePaymentById } from "../../../../../../communication_system/axios_apis/axios_apis";

const SubscribePaymentDetailComponentContainer = ({
  choiceRowIndex,
  page,
  limit,
  item,
  setBodyContents,
}) => {
  const [subscribePaymentDetail, setSubscribePaymentDetail] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await getSubscribePaymentById(item.id);

      console.log(response.data.payment);
      setSubscribePaymentDetail(response.data.payment);
      setLoading(false);
    }

    fetchData();
  }, [item]);

  if (loading) return <div>loading...</div>;

  return (
    <SubscribePaymentDetailComponent
      subscribePaymentDetail={subscribePaymentDetail}
      item={item}
      setBodyContents={setBodyContents}
    ></SubscribePaymentDetailComponent>
  );
};

export default React.memo(SubscribePaymentDetailComponentContainer);

import React, { useCallback, useContext, useState, useEffect } from "react";
import styled from "styled-components";
import {
  rgba_66_139_247_0d05,
  rgb_0_0_0,
  rgb_205_205_205,
  rgb_255_255_255,
} from "../../colors";
import {
  h_px14rate,
  h_px62d5rate,
  h_px671d5rate,
  h_px735d5rate,
  w_px11rate,
  w_px1226rate,
  w_px362rate,
  w_px864rate,
} from "../../size";
import {
  CHECK_BOX,
  DATE,
  HEADER_TITLE,
  MANAGER_MEMO,
  NAME,
  NUMBER,
  PRICE,
  STRING,
  SUBSCRIBE_CATEGORY,
  SUBSCRIBE_STATE,
} from "../../../data_system/data_type/data_type";
import Pagination from "./pagination/Pagination";
import DetailTotalForm from "./detail/forms/detail_total_form/DetailTotalForm";
import { table_date_function } from "../../../data_system/data_functions/table_date_function/table_date_function";
import { table_price_function } from "../../../data_system/data_functions/table_price_function/table_price_function";
import TooltipCard from "../tooltip_card/TooltipCard";
import AdminMemoModalContext from "../../../data_system/contexts/modal_context/admin_memo_modal_context";
import {
  ADMINISTRATOR_DETAIL_COMPONENT,
  NONE_DETAIL_COMPONENT,
  USER_DETAIL_COMPONENT,
} from "../../../data_system/detail_data_type/detail_data_type";
import CheckBox from "../check_box/CheckBox";
import produce from "immer";

const AdminTable = ({
  structureInfo,
  headerTitle,
  bodyContents,
  DETAIL_DATA_TYPE,
  DETAIL_FORM_TYPE,
  DETAIL_DATA_REGISTER,
  detail_disable,
  totalPages,
  page,
  setPage,
  setBodyContents,
  choiceRowIndex,
  setChoiceRowIndex,
  itemOpen,
  setItemOpen,
  registerOpen,
  setRegisterOpen,
}) => {
  const { admin_memo_modal_actions } = useContext(AdminMemoModalContext);

  const [choiceItem, setChoiceItem] = useState(null);
  const limit = 10;

  const onClickRow = useCallback(
    (rowIndex) => {
      if (!detail_disable) {
        console.log("rowIndex", rowIndex);
        setChoiceRowIndex((pre) =>
          pre === null ? rowIndex : pre === rowIndex ? null : rowIndex
        );
        setRegisterOpen(false);
        if (choiceRowIndex === null) {
          setChoiceItem(bodyContents[rowIndex]);
          setItemOpen(true);
        } else {
          if (choiceRowIndex === rowIndex) {
            setItemOpen(false);
          } else {
            setChoiceItem(bodyContents[rowIndex]);
            setItemOpen(true);
          }
        }
      }
    },
    [choiceRowIndex, detail_disable, DETAIL_DATA_TYPE, bodyContents, choiceItem]
  );

  const onClickCheckBox = useCallback(
    (rowIndex) => {
      if (DETAIL_DATA_TYPE === USER_DETAIL_COMPONENT) {
      } else if (DETAIL_DATA_TYPE === ADMINISTRATOR_DETAIL_COMPONENT) {
        setBodyContents(
          produce((draft) => {
            if (!draft || !Array.isArray(draft)) {
              return []; // 또는 적절한 기본값
            }

            // 모든 행을 반복하여 체크 박스 상태를 조정합니다.
            draft.forEach((row, index) => {
              if (row["data"][0]["type"] === CHECK_BOX) {
                // 현재 선택된 행(rowIndex)만 체크 상태를 토글합니다.
                // 나머지 행은 체크 박스를 0(비활성화)로 설정합니다.
                row["data"][0].data =
                  index === rowIndex
                    ? parseInt(row["data"][0].data) === 0
                      ? 1
                      : 0
                    : 0;
              }
            });
          })
        );
      } else if (DETAIL_DATA_TYPE === NONE_DETAIL_COMPONENT) {
      }
    },
    [DETAIL_DATA_TYPE]
  );

  return (
    <>
      {Array.isArray(bodyContents) && bodyContents.length > 0 ? (
        <TotalTableRowSection>
          <TotalTable itemOpen={itemOpen}>
            <TotalTableBody itemOpen={itemOpen}>
              <TotalTableHeader>
                {structureInfo.map((structure, index) => {
                  if (headerTitle[index]["type"] === HEADER_TITLE) {
                    return (
                      <div
                        key={index}
                        style={{
                          width: itemOpen
                            ? structure["open_width"]
                            : structure["close_width"],
                          marginLeft: w_px11rate,
                          marginRight: w_px11rate,
                          boxSizing: "border-box",
                          wordBreak: "break-all",
                        }}
                      >
                        {headerTitle[index]["data"]}
                      </div>
                    );
                  }
                })}
              </TotalTableHeader>
              {bodyContents.map((rowOb, rowIndex) => {
                const id = rowOb["id"];
                const row = rowOb["data"];
                return (
                  <TotalTableRow
                    key={rowIndex}
                    onClick={(e) => {
                      e.preventDefault();
                      onClickRow(rowIndex);
                    }}
                    rowIndex={rowIndex}
                    choiceRowIndex={choiceRowIndex}
                  >
                    {structureInfo.map((structure, colIndex) => {
                      if (row[colIndex]["type"] === DATE) {
                        return (
                          <TotalTableItem
                            key={colIndex}
                            itemOpen={itemOpen}
                            structure={structure}
                          >
                            {table_date_function(row[colIndex]["data"])}
                          </TotalTableItem>
                        );
                      } else if (row[colIndex]["type"] === NAME) {
                        return (
                          <TotalTableItem
                            key={colIndex}
                            itemOpen={itemOpen}
                            structure={structure}
                          >
                            <TotalTableItemName>
                              {row[colIndex]["data"]}
                            </TotalTableItemName>
                          </TotalTableItem>
                        );
                      } else if (row[colIndex]["type"] === STRING) {
                        return (
                          <TotalTableItem
                            key={colIndex}
                            itemOpen={itemOpen}
                            structure={structure}
                          >
                            {row[colIndex]["data"]}
                          </TotalTableItem>
                        );
                      } else if (row[colIndex]["type"] === MANAGER_MEMO) {
                        return (
                          <TotalTableItem
                            key={colIndex}
                            onClick={(e) => {
                              e.stopPropagation();
                              admin_memo_modal_actions.set_DETAIL_DATA_TYPE(
                                DETAIL_DATA_TYPE
                              );
                              admin_memo_modal_actions.setId(id);
                              admin_memo_modal_actions.setAdminMemoMessage(
                                row[colIndex]["data"]
                              );
                              admin_memo_modal_actions.setModalVisible(true);
                            }}
                            itemOpen={itemOpen}
                            structure={structure}
                          >
                            <TooltipCard
                              contents={row[colIndex]["data"]}
                            ></TooltipCard>
                          </TotalTableItem>
                        );
                      } else if (row[colIndex]["type"] === SUBSCRIBE_STATE) {
                        return (
                          <TotalTableItem
                            key={colIndex}
                            itemOpen={itemOpen}
                            structure={structure}
                          >
                            {row[colIndex]["data"] === 0
                              ? "이용 안 함"
                              : row[colIndex]["data"] === 1
                              ? "베이직"
                              : "스탠다드"}
                          </TotalTableItem>
                        );
                      } else if (row[colIndex]["type"] === SUBSCRIBE_CATEGORY) {
                        return (
                          <TotalTableItem
                            key={colIndex}
                            itemOpen={itemOpen}
                            structure={structure}
                          >
                            {row[colIndex]["data"]}
                          </TotalTableItem>
                        );
                      } else if (row[colIndex]["type"] === PRICE) {
                        return (
                          <TotalTableItem
                            key={colIndex}
                            itemOpen={itemOpen}
                            structure={structure}
                          >
                            <PriceText>
                              {table_price_function(row[colIndex]["data"])}
                            </PriceText>
                          </TotalTableItem>
                        );
                      } else if (row[colIndex]["type"] === NUMBER) {
                        return (
                          <TotalTableItem
                            key={colIndex}
                            itemOpen={itemOpen}
                            structure={structure}
                          >
                            {row[colIndex]["data"]}개
                          </TotalTableItem>
                        );
                      } else if (row[colIndex]["type"] === CHECK_BOX) {
                        return (
                          <TotalTableItem
                            key={colIndex}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            itemOpen={itemOpen}
                            structure={structure}
                          >
                            <CheckBox
                              onClick={(e) => {
                                e.stopPropagation();
                                onClickCheckBox(rowIndex);
                              }}
                              checked={parseInt(row[colIndex]["data"])}
                            ></CheckBox>
                          </TotalTableItem>
                        );
                      }
                    })}
                  </TotalTableRow>
                );
              })}
            </TotalTableBody>
            <Pagination
              totalPages={totalPages}
              page={page}
              setPage={setPage}
              setItemOpen={setItemOpen}
              setChoiceRowIndex={setChoiceRowIndex}
              limit={limit}
            ></Pagination>
          </TotalTable>
          {itemOpen && (
            <TotalTableDetail>
              <TotalTableDetailBodyFrame>
                <DetailTotalForm
                  itemOpen={itemOpen}
                  setItemOpen={setItemOpen}
                  choiceRowIndex={choiceRowIndex}
                  setChoiceRowIndex={setChoiceRowIndex}
                  registerOpen={registerOpen}
                  setRegisterOpen={setRegisterOpen}
                  DETAIL_DATA_TYPE={DETAIL_DATA_TYPE}
                  DETAIL_FORM_TYPE={DETAIL_FORM_TYPE}
                  DETAIL_DATA_REGISTER={DETAIL_DATA_REGISTER}
                  limit={limit}
                  page={page}
                  item={choiceItem}
                  setBodyContents={setBodyContents}
                ></DetailTotalForm>
              </TotalTableDetailBodyFrame>
            </TotalTableDetail>
          )}
        </TotalTableRowSection>
      ) : (
        <div>없음...</div>
      )}
    </>
  );
};

export default React.memo(AdminTable);

const TotalTableRowSection = styled.div`
  display: flex;
`;

const TotalTable = styled.div`
  width: ${(props) => (props.itemOpen ? w_px864rate : w_px1226rate)}px;
`;

const TotalTableBody = styled.div`
  border-top: 1px solid ${rgb_0_0_0};
  width: ${(props) => (props.itemOpen ? w_px864rate : w_px1226rate)}px;
`;

const TotalTableHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${rgb_205_205_205};
  height: ${h_px62d5rate}px;
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_medium;
  padding-top: ${h_px14rate}px;
  padding-bottom: ${h_px14rate}px;
`;

const TotalTableRow = styled.div`
  display: flex;
  border-bottom: 0.5px solid ${rgb_205_205_205};
  background-color: ${(props) =>
    props.choiceRowIndex === null || props.choiceRowIndex !== props.rowIndex
      ? rgb_255_255_255
      : rgba_66_139_247_0d05};
`;

const TotalTableItem = styled.div`
  display: flex;
  align-items: center;
  font-size: ${w_px11rate}px;
  width: ${(props) =>
    props.itemOpen
      ? props.structure["open_width"]
      : props.structure["close_width"]}px;
  font-family: noto_sans_kr_regular;
  padding-top: ${h_px14rate}px;
  padding-bottom: ${h_px14rate}px;
  padding-left: ${w_px11rate}px;
  padding-right: ${w_px11rate}px;
  box-sizing: border-box;
  word-break: break-all;
`;

const TotalTableItemName = styled.div`
  font-family: noto_sans_kr_bold;
`;

const TotalTableDetail = styled.div`
  width: ${w_px362rate}px;
  height: ${h_px735d5rate}px;
`;

const TotalTableDetailBodyFrame = styled.div`
  height: ${h_px671d5rate}px;
`;

const PriceText = styled.div`
  font-family: noto_sans_kr_regular;
`;

import {
  DATE,
  HEADER_TITLE,
  MANAGER_MEMO,
  NAME,
  NUMBER,
  PRICE,
  STRING,
  SUBSCRIBE_STATE,
} from "../data_type/data_type";
import { w_px121rate, w_px161rate } from "../../design_system/size";

export const user_structureInfo = [
  {
    id: 0,
    close_width: w_px161rate,
    open_width: w_px121rate,
  },
  {
    id: 1,
    close_width: w_px161rate,
    open_width: w_px121rate,
  },
  {
    id: 2,
    close_width: w_px161rate,
    open_width: w_px121rate,
  },
  {
    id: 3,
    close_width: w_px161rate,
    open_width: w_px121rate,
  },
  {
    id: 4,
    close_width: w_px161rate,
    open_width: w_px121rate,
  },
  {
    id: 5,
    close_width: w_px161rate,
    open_width: w_px121rate,
  },
  {
    id: 6,
    close_width: w_px161rate,
    open_width: w_px121rate,
  },
  {
    id: 7,
    close_width: w_px161rate,
    open_width: w_px121rate,
  },
];

export const user_headerTitle = [
  {
    type: HEADER_TITLE,
    data: "가입 일시",
  },
  {
    type: HEADER_TITLE,
    data: "이름",
  },
  {
    type: HEADER_TITLE,
    data: "이메일 주소",
  },
  {
    type: HEADER_TITLE,
    data: "휴대폰 번호",
  },
  {
    type: HEADER_TITLE,
    data: "숙소 개수",
  },
  {
    type: HEADER_TITLE,
    data: "월 결제금액",
  },
  {
    type: HEADER_TITLE,
    data: "구독 상태",
  },
  {
    type: HEADER_TITLE,
    data: "관리자 메모",
  },
];

export const user_bodyContents = [
  {
    id: 0,
    data: [
      {
        type: DATE,
        data: new Date(),
      },
      {
        type: NAME,
        data: "나르는갱얼쥐",
      },
      {
        type: STRING,
        data: "tjdwls452@naver.com",
      },
      {
        type: STRING,
        data: "010-1111-2222",
      },
      {
        type: NUMBER,
        data: 2,
      },
      {
        type: PRICE,
        data: 50000,
      },
      {
        type: SUBSCRIBE_STATE,
        data: 0,
      },
      {
        type: MANAGER_MEMO,
        data: "메모메모메모",
      },
    ],
  },
];

import React from "react";
import {
  ADMINISTRATOR_DETAIL_COMPONENT,
  NONE_DETAIL_COMPONENT,
  STORE_PAYMENT_DETAIL_COMPONENT,
  SUBSCRIBE_PAYMENT_DETAIL_COMPONENT,
  USER_DETAIL_COMPONENT,
} from "../../../../../../data_system/detail_data_type/detail_data_type";
import UserDetailComponentContainer from "../user_detail_component/UserDetailComponentContainer";
import AdministratorDetailComponentContainer from "../administrator_detail_component/AdministratorDetailComponentContainer";
import SubscribePaymentDetailComponentContainer from "../subscribe_payment_detail_component/SubscribePaymentDetailComponentContainer";
import StorePaymentDetailComponentContainer from "../store_payment_detail_component/StorePaymentDetailComponentContainer";

const TotalDetailComponent = ({
  choiceRowIndex,
  DETAIL_DATA_TYPE,
  limit,
  page,
  item,
  setBodyContents,
}) => {
  if (DETAIL_DATA_TYPE === USER_DETAIL_COMPONENT) {
    return (
      <UserDetailComponentContainer
        choiceRowIndex={choiceRowIndex}
        limit={limit}
        page={page}
        item={item}
        setBodyContents={setBodyContents}
      ></UserDetailComponentContainer>
    );
  } else if (DETAIL_DATA_TYPE === ADMINISTRATOR_DETAIL_COMPONENT) {
    return (
      <AdministratorDetailComponentContainer
        choiceRowIndex={choiceRowIndex}
        limit={limit}
        page={page}
        item={item}
        setBodyContents={setBodyContents}
      ></AdministratorDetailComponentContainer>
    );
  } else if (DETAIL_DATA_TYPE === SUBSCRIBE_PAYMENT_DETAIL_COMPONENT) {
    return (
      <SubscribePaymentDetailComponentContainer
        choiceRowIndex={choiceRowIndex}
        limit={limit}
        page={page}
        item={item}
        setBodyContents={setBodyContents}
      ></SubscribePaymentDetailComponentContainer>
    );
  } else if (DETAIL_DATA_TYPE === STORE_PAYMENT_DETAIL_COMPONENT) {
    return (
      <StorePaymentDetailComponentContainer
        choiceRowIndex={choiceRowIndex}
        limit={limit}
        page={page}
        item={item}
        setBodyContents={setBodyContents}
      ></StorePaymentDetailComponentContainer>
    );
  } else if (DETAIL_DATA_TYPE === NONE_DETAIL_COMPONENT) {
    return <></>;
  }
};

export default React.memo(TotalDetailComponent);

import React, { useEffect, useMemo, useState } from "react";
import UserDetailComponent from "./UserDetailComponent";
import { getUserById } from "../../../../../../communication_system/axios_apis/axios_apis";

const UserDetailComponentContainer = ({
  choiceRowIndex,
  limit,
  page,
  item,
  setBodyContents,
}) => {
  const [userDetail, setUserDetail] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await getUserById(item.id);

      console.log(response.data);
      setUserDetail(response.data.user);
      setLoading(false);
    }

    fetchData();
  }, [item]);

  if (loading) return <div>loading...</div>;

  return (
    <UserDetailComponent
      userDetail={userDetail}
      item={item}
      setBodyContents={setBodyContents}
    ></UserDetailComponent>
  );
};

export default React.memo(UserDetailComponentContainer);

import React, { useCallback, useState } from "react";
import { table_price_function } from "../../../../../../data_system/data_functions/table_price_function/table_price_function";
import { only_number } from "../../../../../../data_system/validation_check/validation_check";
import { WhitePlus1x } from "../../../../../icons/icons";
import {
  h_px10rate,
  h_px12rate,
  h_px14rate,
  h_px25rate,
  h_px28rate,
  h_px80rate,
  w_px11rate,
  w_px12rate,
  w_px215rate,
  w_px25rate,
  w_px26rate,
  w_px54rate,
  w_px6rate,
  w_px74rate,
  w_px8rate,
} from "../../../../../size";
import BlueBtn from "../../../../blue_btn/BlueBtn";
import ContentText from "../../../../content_text/ContentText";
import DetailTextarea from "../../../../detail_textarea/DetailTextarea";
import DetailTextInput from "../../../../detail_text_input/DetailTextInput";
import DivisionWhiteBlueLine from "../../../../division_white_blue_line/DivisionWhiteBlueLine";
import ListContent from "../../../../list_content/ListContent";
import ListName from "../../../../list_name/ListName";
import PinkBtn from "../../../../pink_btn/PinkBtn";
import SubTitle from "../../../../sub_title/SubTitle";
import UserName from "../../../../user_name/UserName";
import WhiteBtn from "../../../../white_btn/WhiteBtn";
import { table_date_function } from "../../../../../../data_system/data_functions/table_date_function/table_date_function";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { getStorePaymentHistoriesByUserIdForExcel } from "../../../../../../communication_system/axios_apis/axios_apis";

const StorePaymentDetailComponent = ({ storePaymentDetail, item }) => {
  console.log(storePaymentDetail);

  // const totalSubscriptionEndDate = moment
  //   .utc(subscribePaymentDetail.User.totalSubscriptionEndDate)
  //   .clone()
  //   .toDate();

  // const totalSubscriptionStartDate = moment
  //   .utc(subscribePaymentDetail.User.totalSubscriptionStartDate)
  //   .clone()
  //   .toDate();

  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <div>
      <div
        style={{
          paddingTop: h_px25rate,
          paddingLeft: w_px25rate,
          display: "flex",
        }}
      >
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="yyyy/MM"
          showMonthYearPicker
          locale={ko}
          customInput={
            <button
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                color: "white",
                backgroundColor: "#1890ff",
                border: "none",
                borderRadius: "12px",
                cursor: "pointer",
              }}
            >
              {selectedDate
                ? `${selectedDate.getFullYear()}/${selectedDate.getMonth() + 1}`
                : "Select Month"}
            </button>
          }
        />
        <div
          style={{
            fontFamily: "noto_sans_kr_bold",
            cursor: "pointer",
            fontSize: w_px12rate,
            border: "1px solid green",
            padding: "8px",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "150px",
          }}
          onClick={async () => {
            const year = selectedDate.getFullYear();
            const month = selectedDate.getMonth() + 1;
            const response = await getStorePaymentHistoriesByUserIdForExcel(
              item.id,
              year,
              month
            );

            console.log(response.data.storePaymentHistories);
            const customData = response.data.storePaymentHistories.map(
              (row, index) => [
                index + 1,
                table_date_function(row.createdAt),
                row.payerName,
                row.payerEmail,
                row.payerHp,
                row.userProduct.title,
                row.number,
                row.userProduct.price,
                row.totalPrice,
                row.paymentTypeId === 2
                  ? "국내카드"
                  : row.paymentTypeId === 4
                  ? "해외카드"
                  : "",
              ]
            );
            // 첫 번째 행에 열 제목을 추가
            const worksheet = XLSX.utils.aoa_to_sheet([
              [
                "No.",
                "날짜",
                "결제자(이름)",
                "결제자 이메일",
                "결제자 핸드폰 번호",
                "상품명",
                "판매 개수",
                "가격",
                "총 금액",
                "결제수단",
              ],
            ]);
            XLSX.utils.sheet_add_aoa(worksheet, customData, { origin: -1 });
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            // 열 너비 설정 (선택 사항)
            const colWidths = [
              { wpx: 50 },
              { wpx: 150 },
              { wpx: 100 },
              { wpx: 100 },
              { wpx: 100 },
              { wpx: 100 },
              { wpx: 50 },
              { wpx: 100 },
              { wpx: 100 },
              { wpx: 100 },
            ];
            worksheet["!cols"] = colWidths;
            const excelBuffer = XLSX.write(workbook, {
              bookType: "xlsx",
              type: "array",
            });
            const blob = new Blob([excelBuffer], {
              type: "application/octet-stream",
            });
            saveAs(blob, "payments.xlsx");
          }}
        >
          엑셀 다운로드
        </div>
      </div>
      <div
        style={{
          paddingTop: h_px25rate,
          paddingLeft: w_px25rate,
        }}
      >
        {storePaymentDetail.map((storePayment, index) => {
          return (
            <div
              key={index}
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: "5px",
                marginBottom: h_px14rate,
                padding: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginBottom: h_px12rate,
                }}
              >
                <ListName>날짜</ListName>
                <div
                  style={{
                    display: "flex",
                    marginLeft: w_px26rate,
                  }}
                >
                  <ContentText>
                    {table_date_function(storePayment.createdAt)}
                  </ContentText>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginBottom: h_px12rate,
                }}
              >
                <ListName>결제자</ListName>
                <div
                  style={{
                    display: "flex",
                    marginLeft: w_px26rate,
                  }}
                >
                  <ContentText>{storePayment.payerName}</ContentText>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginBottom: h_px12rate,
                }}
              >
                <ListName>결제자 이메일</ListName>
                <div
                  style={{
                    display: "flex",
                    marginLeft: w_px26rate,
                  }}
                >
                  <ContentText>{storePayment.payerEmail}</ContentText>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginBottom: h_px12rate,
                }}
              >
                <ListName>결제자 핸드폰 번호</ListName>
                <div
                  style={{
                    display: "flex",
                    marginLeft: w_px26rate,
                  }}
                >
                  <ContentText>{storePayment.payerHp}</ContentText>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginBottom: h_px12rate,
                }}
              >
                <ListName>상품명</ListName>
                <div
                  style={{
                    display: "flex",
                    marginLeft: w_px26rate,
                  }}
                >
                  <ContentText>{storePayment.userProduct.title}</ContentText>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginBottom: h_px12rate,
                }}
              >
                <ListName>판매 개수</ListName>
                <div
                  style={{
                    display: "flex",
                    marginLeft: w_px26rate,
                  }}
                >
                  <ContentText>
                    {table_price_function(storePayment.number)}
                  </ContentText>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginBottom: h_px12rate,
                }}
              >
                <ListName>가격</ListName>
                <div
                  style={{
                    display: "flex",
                    marginLeft: w_px26rate,
                  }}
                >
                  <ContentText>
                    {table_price_function(storePayment.userProduct.price)}
                  </ContentText>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginBottom: h_px12rate,
                }}
              >
                <ListName>총 금액</ListName>
                <div
                  style={{
                    display: "flex",
                    marginLeft: w_px26rate,
                  }}
                >
                  <ContentText>
                    {table_price_function(storePayment.totalPrice)}
                  </ContentText>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginBottom: h_px12rate,
                }}
              >
                <ListName>결제수단</ListName>
                <div
                  style={{
                    display: "flex",
                    marginLeft: w_px26rate,
                  }}
                >
                  <ContentText>
                    {storePayment.paymentTypeId === 2
                      ? "국내카드"
                      : storePayment.paymentTypeId === 3
                      ? "계좌이체"
                      : storePayment.paymentTypeId === 4
                      ? "해외카드"
                      : ""}
                  </ContentText>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(StorePaymentDetailComponent);

import { createContext, useState } from "react";

const AddScheduleModalContext = createContext({
  add_schedule_modal_state: {
    modalVisible: false,
  },
  add_schedule_modal_actions: {
    setModalVisible: () => {},
  },
});

const AddScheduleModalProvider = ({ children }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const value = {
    add_schedule_modal_state: { modalVisible },
    add_schedule_modal_actions: {
      setModalVisible,
    },
  };

  return (
    <AddScheduleModalContext.Provider value={value}>
      {children}
    </AddScheduleModalContext.Provider>
  );
};

const AddScheduleModalConsumer = AddScheduleModalContext.Consumer;

export { AddScheduleModalConsumer, AddScheduleModalProvider };

export default AddScheduleModalContext;

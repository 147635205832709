import React, { useState, createContext } from "react";

// Context 생성
export const BodyContentsContext = createContext();

// Provider 컴포넌트
export const BodyContentsProvider = ({ children }) => {
  const [bodyContents, setBodyContents] = useState([]);

  return (
    <BodyContentsContext.Provider value={{ bodyContents, setBodyContents }}>
      {children}
    </BodyContentsContext.Provider>
  );
};

import React, { useEffect, useState } from "react";
import { getVisitorStatsForCurrentYear } from "../../communication_system/axios_apis/axios_apis";

const ViewScore = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      setLoading(true);

      const response = await getVisitorStatsForCurrentYear();
      console.log(response);

      if (response.status === 200) {
        setStats(response.data.stats);
      }

      setLoading(false);
    }

    getData();
  }, []);

  return (
    !loading && (
      <>
        <div>
          <div>
            {stats.currentYear}년 총 방문자: {stats.yearlyTotalCount}
          </div>
          <br></br>
          <div>
            현재 {stats.currentYear}년 {stats.currentMonth}월
          </div>
          <br></br>
          <div>
            {stats.monthlyVisitorCount.map((monthlyVisitorCount, index) => (
              <div key={index}>
                <div>
                  {index + 1}월 방문자: {monthlyVisitorCount}
                </div>
                <br></br>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  );
};

export default React.memo(ViewScore);

import { CHECK_BOX, HEADER_TITLE, NAME, STRING } from "../data_type/data_type";
import { w_px242rate, w_px322rate } from "../../design_system/size";

export const administrator_structureInfo = [
  {
    id: 0,
    close_width: w_px322rate,
    open_width: w_px242rate,
  },
  {
    id: 1,
    close_width: w_px322rate,
    open_width: w_px242rate,
  },
  {
    id: 2,
    close_width: w_px322rate,
    open_width: w_px242rate,
  },
  {
    id: 3,
    close_width: w_px322rate,
    open_width: w_px242rate,
  },
];

export const administrator_headerTitle = [
  {
    type: HEADER_TITLE,
    data: "",
  },
  {
    type: HEADER_TITLE,
    data: "이름",
  },
  {
    type: HEADER_TITLE,
    data: "계정",
  },
  {
    type: HEADER_TITLE,
    data: "연락처",
  },
];

export const administrator_bodyContents = [
  {
    id: 0,
    data: [
      {
        type: CHECK_BOX,
        data: 0,
      },
      {
        type: NAME,
        data: "나르는갱얼쥐",
      },
      {
        type: STRING,
        data: "whocares@gmail.com",
      },
      {
        type: STRING,
        data: "010-1111-2222",
      },
    ],
  },
];

import React, { useCallback, useContext, useEffect, useState } from "react";
import AdminTable from "../../design_system/components/admin_table/AdminTable";
import {
  NONE_DETAIL_COMPONENT,
  STORE_PAYMENT_DETAIL_COMPONENT,
} from "../../data_system/detail_data_type/detail_data_type";
import {
  DETAIL_COMMON_FORM,
  DETAIL_NONE_FORM,
} from "../../design_system/components/admin_table/detail/forms/detail_form_type/detail_form_type";
import {
  store_payment_bodyContents,
  store_payment_headerTitle,
  store_payment_structureInfo,
} from "../../data_system/store_payment_data/store_payment_data";
import { NONE_DETAIL_REGISTER } from "../../data_system/register_data_type/register_data_type";
import { h_px22rate, w_px4rate } from "../../design_system/size";
import styled from "styled-components";
import LongDropdown from "../../design_system/components/long_dropdown/LongDropdown";
import SearchBar from "../../design_system/components/searchbar/SearchBar";
import { BodyContentsContext } from "../../data_system/contexts/body_contents_context/body_contents_context";
import { getStorePaymentsByPageWithCondition } from "../../communication_system/axios_apis/axios_apis";
import {
  NAME,
  NUMBER,
  PRICE,
  STRING,
} from "../../data_system/data_type/data_type";
import { formatBankAccount } from "../../data_system/data_functions/format_bank_account";

const StorePayment = () => {
  const { bodyContents, setBodyContents } = useContext(BodyContentsContext);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  const [choiceRowIndex, setChoiceRowIndex] = useState(null);
  const [itemOpen, setItemOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);

  const [userDetailDropdownItem, setUserDetailDropdownItem] = useState({
    id: 0,
    title: "이름",
    selected: false,
    key: "name",
    value: 1,
  });

  const [userDetailList, setUserDetailList] = useState([
    {
      id: 0,
      title: "이름",
      selected: false,
      key: "name",
      value: 1,
    },
    {
      id: 1,
      title: "이메일 주소",
      selected: false,
      key: "email",
      value: 2,
    },
    {
      id: 2,
      title: "휴대폰 번호",
      selected: false,
      key: "phone_number",
      value: 3,
    },
  ]);

  const [page, setPage] = useState(1);

  const userDetailResetThenSet = useCallback((item) => {
    setUserDetailDropdownItem(item);
  }, []);

  const onChangeSearchText = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const onSearch = useCallback(async () => {
    const response = await getStorePaymentsByPageWithCondition(
      page,
      userDetailDropdownItem.value,
      searchText
    );
    console.log(response);
    let tempBodyContents = [];
    for (let i = 0; i < response.data.usersWithPayments.length; i++) {
      const bankInfo = formatBankAccount(
        response.data.usersWithPayments[i].bankTypeId,
        response.data.usersWithPayments[i].bankAccountNumber
      );

      tempBodyContents.push({
        id: response.data.usersWithPayments[i].id,
        data: [
          {
            type: NAME,
            data: response.data.usersWithPayments[i].name,
          },
          {
            type: STRING,
            data: response.data.usersWithPayments[i].email,
          },
          {
            type: STRING,
            data: response.data.usersWithPayments[i].phoneNumber,
          },
          {
            type: NUMBER,
            data: response.data.usersWithPayments[i].maxGuideNumber,
          },
          {
            type: STRING,
            data: bankInfo[0],
          },
          {
            type: STRING,
            data: bankInfo[1],
          },
          {
            type: STRING,
            data: response.data.usersWithPayments[i].bankAccountName,
          },
          {
            type: PRICE,
            data: parseInt(
              response.data.usersWithPayments[i].previousMonthTotalPrice
            ),
          },
          {
            type: PRICE,
            data: parseInt(
              response.data.usersWithPayments[i].currentMonthTotalPrice
            ),
          },
          {
            type: PRICE,
            data: parseInt(response.data.usersWithPayments[i].totalPrice),
          },
        ],
      });
    }

    setBodyContents(tempBodyContents);
    setTotalPages(response.data.totalPages);
  }, [page, userDetailDropdownItem.value, searchText]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await getStorePaymentsByPageWithCondition(
        page,
        userDetailDropdownItem.value,
        searchText
      );
      console.log(response);
      let tempBodyContents = [];
      for (let i = 0; i < response.data.usersWithPayments.length; i++) {
        const bankInfo = formatBankAccount(
          response.data.usersWithPayments[i].bankTypeId,
          response.data.usersWithPayments[i].bankAccountNumber
        );

        tempBodyContents.push({
          id: response.data.usersWithPayments[i].id,
          data: [
            {
              type: NAME,
              data: response.data.usersWithPayments[i].name,
            },
            {
              type: STRING,
              data: response.data.usersWithPayments[i].email,
            },
            {
              type: STRING,
              data: response.data.usersWithPayments[i].phoneNumber,
            },
            {
              type: NUMBER,
              data: response.data.usersWithPayments[i].maxGuideNumber,
            },
            {
              type: STRING,
              data: bankInfo[0],
            },
            {
              type: STRING,
              data: bankInfo[1],
            },
            {
              type: STRING,
              data: response.data.usersWithPayments[i].bankAccountName,
            },
            {
              type: PRICE,
              data: parseInt(
                response.data.usersWithPayments[i].previousMonthTotalPrice
              ),
            },
            {
              type: PRICE,
              data: parseInt(
                response.data.usersWithPayments[i].currentMonthTotalPrice
              ),
            },
            {
              type: PRICE,
              data: parseInt(response.data.usersWithPayments[i].totalPrice),
            },
          ],
        });
      }

      setBodyContents(tempBodyContents);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    }

    fetchData();
  }, [page]);

  if (loading) return <div>loading...</div>;

  return (
    <>
      <>
        <SearchSection>
          <LongDropdown
            list={userDetailList}
            resetThenSet={userDetailResetThenSet}
            index={0}
          ></LongDropdown>
          <div
            style={{
              marginLeft: w_px4rate,
            }}
          >
            <SearchBar
              searchText={searchText}
              onChangeSearchText={onChangeSearchText}
              onSearch={onSearch}
            ></SearchBar>
          </div>
        </SearchSection>
      </>
      <AdminTable
        structureInfo={store_payment_structureInfo}
        headerTitle={store_payment_headerTitle}
        bodyContents={bodyContents}
        DETAIL_DATA_TYPE={STORE_PAYMENT_DETAIL_COMPONENT}
        DETAIL_FORM_TYPE={DETAIL_COMMON_FORM}
        DETAIL_DATA_REGISTER={NONE_DETAIL_REGISTER}
        detail_disable={false}
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        setBodyContents={setBodyContents}
        choiceRowIndex={choiceRowIndex}
        setChoiceRowIndex={setChoiceRowIndex}
        itemOpen={itemOpen}
        setItemOpen={setItemOpen}
        registerOpen={registerOpen}
        setRegisterOpen={setRegisterOpen}
      ></AdminTable>
    </>
  );
};

export default React.memo(StorePayment);

const SearchSection = styled.div`
  position: relative;
  display: flex;
  margin-bottom: ${h_px22rate}px;
  z-index: 999;
`;

import React, { useCallback, useState } from "react";
import styled from "styled-components";
import {
  h_px12rate,
  h_px14rate,
  h_px16rate,
  h_px25rate,
  h_px28rate,
  h_px6rate,
  w_px11rate,
  w_px145rate,
  w_px25rate,
  w_px26rate,
  w_px54rate,
  w_px5rate,
  w_px6rate,
  w_px74rate,
  w_px8rate,
} from "../../../../../size";
import RadioBtn from "../../../../radio_btn/RadioBtn";
import DivisionWhiteBlueLine from "../../../../division_white_blue_line/DivisionWhiteBlueLine";
import DetailTextInput from "../../../../detail_text_input/DetailTextInput";
import SubTitle from "../../../../sub_title/SubTitle";
import ListName from "../../../../list_name/ListName";
import ContentText from "../../../../content_text/ContentText";
import WhiteBtn from "../../../../white_btn/WhiteBtn";
import PinkBtn from "../../../../pink_btn/PinkBtn";
import { table_date_function } from "../../../../../../data_system/data_functions/table_date_function/table_date_function";
import {
  only_number,
  phone_no_check,
} from "../../../../../../data_system/validation_check/validation_check";
import ListContent from "../../../../list_content/ListContent";
import BlueBtn from "../../../../blue_btn/BlueBtn";
import { WhitePlus1x } from "../../../../../icons/icons";
import {
  putProcessUserRefund,
  putUserInformationByAdmin,
} from "../../../../../../communication_system/axios_apis/axios_apis";
import moment from "moment";

const UserDetailComponent = ({ userDetail, item, setBodyContents }) => {
  console.log(userDetail);
  const [name, setName] = useState(userDetail.name);

  const onChangeName = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const [phoneNo, setPhoneNo] = useState(userDetail.phoneNumber);

  const onChangePhoneNo = useCallback((e) => {
    setPhoneNo(e.target.value);
  }, []);

  const [userStatus, setUserStatus] = useState(userDetail.blocked ? 1 : 0);

  const onClickUserStatusYes = useCallback(() => {
    setUserStatus(0);
  }, []);

  const onClickUserStatusNo = useCallback(() => {
    setUserStatus(1);
  }, []);

  const [totalSubscriptionEndDate, setTotalSubscriptionEndDate] = useState(
    userDetail.totalSubscriptionEndDate
  );

  const [addDate, setAddDate] = useState("");

  const onChangeAddDate = useCallback((e) => {
    if (only_number.test(e.target.value)) {
      setAddDate(e.target.value);
    }
  }, []);

  const increaseDateByDays = (dateStr, days) => {
    const date = moment.utc(dateStr).clone().toDate();

    date.setDate(date.getDate() + days);

    return date;
  };

  return (
    <div
      style={{
        paddingTop: h_px25rate,
        paddingLeft: w_px25rate,
      }}
    >
      <div
        style={{
          marginBottom: h_px14rate,
        }}
      >
        <SubTitle>프로필 정보</SubTitle>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>이름</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <DetailTextInput
            width={w_px145rate}
            height={h_px16rate}
            font_size={w_px11rate}
            value={name}
            onChange={onChangeName}
          ></DetailTextInput>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>이메일</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>{userDetail.email}</ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>휴대폰 번호</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <DetailTextInput
            width={w_px145rate}
            height={h_px16rate}
            font_size={w_px11rate}
            value={phoneNo}
            onChange={onChangePhoneNo}
          ></DetailTextInput>
        </div>
      </div>
      <div
        style={{
          marginBottom: h_px12rate,
          alignItems: "center",
        }}
      >
        <DivisionWhiteBlueLine></DivisionWhiteBlueLine>
      </div>
      <div
        style={{
          marginBottom: h_px14rate,
        }}
      >
        <SubTitle>추가 정보</SubTitle>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>가입일</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>{table_date_function(userDetail.createdAt)}</ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: h_px12rate,
        }}
      >
        <div
          style={{
            marginRight: w_px26rate,
          }}
        >
          <ListName>이용 상태</ListName>
        </div>
        <div>
          <div
            style={{
              marginBottom: h_px6rate,
            }}
          >
            <RadioTotalFrame>
              <RadioFrame>
                <RadioBtn
                  checked={parseInt(userStatus) === 0 ? true : false}
                  onClick={onClickUserStatusYes}
                ></RadioBtn>
                <div
                  style={{
                    marginLeft: w_px5rate,
                  }}
                >
                  <ContentText>정상</ContentText>
                </div>
              </RadioFrame>
              <div
                style={{
                  marginLeft: w_px5rate,
                }}
              >
                <RadioFrame>
                  <RadioBtn
                    checked={parseInt(userStatus) === 1 ? true : false}
                    onClick={onClickUserStatusNo}
                  ></RadioBtn>
                  <div
                    style={{
                      marginLeft: w_px5rate,
                    }}
                  >
                    <ContentText>이용차단</ContentText>
                  </div>
                </RadioFrame>
              </div>
            </RadioTotalFrame>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>구독 만료 날짜</ListName>
        <div
          style={{
            marginLeft: w_px26rate,
          }}
        >
          <ContentText>
            {table_date_function(totalSubscriptionEndDate)}
          </ContentText>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: h_px12rate,
        }}
      >
        <ListName>구독권 추가</ListName>
        <div
          style={{
            display: "flex",
            marginLeft: w_px26rate,
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <DetailTextInput
            width={w_px74rate}
            height={h_px28rate}
            placeholder={"직접 입력"}
            value={addDate}
            onChange={onChangeAddDate}
          ></DetailTextInput>
          <div
            style={{
              marginLeft: w_px6rate,
            }}
          >
            <ListContent>일</ListContent>
          </div>
          <div
            style={{
              marginLeft: w_px6rate,
            }}
          >
            <BlueBtn
              width={w_px54rate}
              height={h_px28rate}
              onClick={() => {
                setTotalSubscriptionEndDate(
                  increaseDateByDays(
                    totalSubscriptionEndDate,
                    parseInt(addDate)
                  )
                );
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                <WhitePlus1x></WhitePlus1x> <div>추가</div>
              </div>
            </BlueBtn>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: h_px12rate,
        }}
      >
        <WhiteBtn
          onClick={async () => {
            const response = await putProcessUserRefund(item.id);

            if (response.status === 200) {
              alert("환불 처리가 완료되었습니다.");
            }
          }}
        >
          환불
        </WhiteBtn>
        <div
          style={{
            marginLeft: w_px8rate,
          }}
        >
          <PinkBtn
            onClick={async () => {
              if (
                name !== "" &&
                phoneNo !== "" &&
                phone_no_check.test(phoneNo)
              ) {
                const response = await putUserInformationByAdmin(item.id, {
                  name,
                  phoneNumber: phoneNo,
                  blocked: userStatus === 0 ? false : true,
                  totalSubscriptionEndDate,
                });

                console.log(response);

                if (response.status === 200) {
                  alert("사용자 정보가 성공적으로 업데이트되었습니다.");
                  setBodyContents((prevContents) => {
                    return prevContents.map((content) => {
                      if (content.id === item.id) {
                        // 이름, 이메일, 휴대폰 번호의 위치가 각각 1, 2, 3번째라고 가정
                        const updatedData = [...content.data];
                        updatedData[1] = { ...updatedData[1], data: name }; // 이름 업데이트
                        updatedData[3] = { ...updatedData[3], data: phoneNo }; // 휴대폰 번호 업데이트

                        return { ...content, data: updatedData };
                      } else {
                        return content;
                      }
                    });
                  });
                }
              }
            }}
          >
            저장
          </PinkBtn>
        </div>
      </div>
    </div>
  );
};

export default React.memo(UserDetailComponent);

const RadioTotalFrame = styled.div`
  display: flex;
`;

const RadioFrame = styled.div`
  display: flex;
  align-items: center;
`;

import React from "react";
import {
  DETAIL_COMMON_FORM,
  DETAIL_NONE_FORM,
} from "../detail_form_type/detail_form_type";
import DetailCommonForm from "../detail_common_form/DetailCommonForm";
import {
  ADMINISTRATOR_DETAIL_COMPONENT,
  STORE_PAYMENT_DETAIL_COMPONENT,
  SUBSCRIBE_PAYMENT_DETAIL_COMPONENT,
  USER_DETAIL_COMPONENT,
} from "../../../../../../data_system/detail_data_type/detail_data_type";

const TotalDetailForm = ({
  setItemOpen,
  choiceRowIndex,
  setChoiceRowIndex,
  registerOpen,
  setRegisterOpen,
  DETAIL_DATA_TYPE,
  DETAIL_FORM_TYPE,
  DETAIL_DATA_REGISTER,
  limit,
  page,
  item,
  setBodyContents,
}) => {
  if (DETAIL_FORM_TYPE === DETAIL_COMMON_FORM) {
    if (DETAIL_DATA_TYPE === USER_DETAIL_COMPONENT) {
      return (
        <DetailCommonForm
          setItemOpen={setItemOpen}
          choiceRowIndex={choiceRowIndex}
          setChoiceRowIndex={setChoiceRowIndex}
          registerOpen={registerOpen}
          setRegisterOpen={setRegisterOpen}
          DETAIL_DATA_TYPE={DETAIL_DATA_TYPE}
          DETAIL_DATA_REGISTER={DETAIL_DATA_REGISTER}
          limit={limit}
          page={page}
          item={item}
          setBodyContents={setBodyContents}
        ></DetailCommonForm>
      );
    } else if (DETAIL_DATA_TYPE === ADMINISTRATOR_DETAIL_COMPONENT) {
      return (
        <DetailCommonForm
          setItemOpen={setItemOpen}
          choiceRowIndex={choiceRowIndex}
          setChoiceRowIndex={setChoiceRowIndex}
          registerOpen={registerOpen}
          setRegisterOpen={setRegisterOpen}
          DETAIL_DATA_TYPE={DETAIL_DATA_TYPE}
          DETAIL_DATA_REGISTER={DETAIL_DATA_REGISTER}
          limit={limit}
          page={page}
          item={item}
          setBodyContents={setBodyContents}
        ></DetailCommonForm>
      );
    } else if (DETAIL_DATA_TYPE === SUBSCRIBE_PAYMENT_DETAIL_COMPONENT) {
      return (
        <DetailCommonForm
          setItemOpen={setItemOpen}
          choiceRowIndex={choiceRowIndex}
          setChoiceRowIndex={setChoiceRowIndex}
          registerOpen={registerOpen}
          setRegisterOpen={setRegisterOpen}
          DETAIL_DATA_TYPE={DETAIL_DATA_TYPE}
          DETAIL_DATA_REGISTER={DETAIL_DATA_REGISTER}
          limit={limit}
          page={page}
          item={item}
          setBodyContents={setBodyContents}
        ></DetailCommonForm>
      );
    } else if (DETAIL_DATA_TYPE === STORE_PAYMENT_DETAIL_COMPONENT) {
      return (
        <DetailCommonForm
          setItemOpen={setItemOpen}
          choiceRowIndex={choiceRowIndex}
          setChoiceRowIndex={setChoiceRowIndex}
          registerOpen={registerOpen}
          setRegisterOpen={setRegisterOpen}
          DETAIL_DATA_TYPE={DETAIL_DATA_TYPE}
          DETAIL_DATA_REGISTER={DETAIL_DATA_REGISTER}
          limit={limit}
          page={page}
          item={item}
          setBodyContents={setBodyContents}
        ></DetailCommonForm>
      );
    }
  } else if (DETAIL_FORM_TYPE === DETAIL_NONE_FORM) {
    return <></>;
  }
};

export default React.memo(TotalDetailForm);

import { useContext } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import DeleteModalContext from "../../../data_system/contexts/modal_context/delete_modal_context";
import {
  rgba_0_0_0_0d1,
  rgb_0_0_0,
  rgb_121_121_121,
  rgb_205_205_205,
  rgb_255_255_255,
  rgb_66_139_247,
} from "../../colors";
import {
  h_px131rate,
  h_px224rate,
  h_px92rate,
  w_px189rate,
  w_px20rate,
  w_px22rate,
  w_px378rate,
} from "../../size";

function DeleteModal() {
  const { delete_modal_actions } = useContext(DeleteModalContext);

  return createPortal(
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: rgba_0_0_0_0d1,
        zIndex: 999,
      }}
    >
      <div
        style={{
          width: w_px378rate,
          height: h_px224rate,
          backgroundColor: rgb_255_255_255,
          borderRadius: w_px20rate,
        }}
      >
        <div
          style={{
            height: h_px131rate,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <QText>정말로 삭제하시겠어요?</QText>
        </div>
        <div
          style={{
            height: 1,
            backgroundColor: rgb_205_205_205,
          }}
        ></div>
        <div
          style={{
            display: "flex",
            boxSizing: "border-box",
          }}
        >
          <NoBtn
            onClick={() => {
              delete_modal_actions.setModalVisible(false);
            }}
          >
            아니오
          </NoBtn>
          <div
            style={{
              width: 1,
              backgroundColor: rgb_205_205_205,
            }}
          ></div>
          <YesBtn
            onClick={() => {
              delete_modal_actions.setModalVisible(false);
            }}
          >
            네
          </YesBtn>
        </div>
      </div>
    </div>,
    document.getElementById("deleteModal")
  );
}

export default DeleteModal;

const QText = styled.div`
  font-size: ${w_px20rate}px;
  font-family: noto_sans_kr_regular;
  color: ${rgb_0_0_0};
`;

const NoBtn = styled.button`
  font-size: ${w_px22rate}px;
  font-family: noto_sans_kr_bold;
  color: ${rgb_121_121_121};
  width: ${w_px189rate}px;
  height: ${h_px92rate}px;
  padding: 0px;
  margin: 0px;
  border-width: 0px;
  background-color: ${rgb_255_255_255};
  box-sizing: border-box;
  border-bottom-left-radius: ${w_px20rate}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const YesBtn = styled.button`
  font-size: ${w_px22rate}px;
  font-family: noto_sans_kr_bold;
  color: ${rgb_66_139_247};
  width: ${w_px189rate}px;
  height: ${h_px92rate}px;
  padding: 0px;
  margin: 0px;
  border-width: 0px;
  background-color: ${rgb_255_255_255};
  box-sizing: border-box;
  border-bottom-right-radius: ${w_px20rate}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import React, { useEffect, useState, useCallback, useContext } from "react";
import {
  administrator_bodyContents,
  administrator_headerTitle,
  administrator_structureInfo,
} from "../../data_system/administrator_data/administrator_data";
import AdminTable from "../../design_system/components/admin_table/AdminTable";
import {
  ADMINISTRATOR_DETAIL_COMPONENT,
  SUBSCRIBE_PAYMENT_DETAIL_COMPONENT,
} from "../../data_system/detail_data_type/detail_data_type";
import { DETAIL_COMMON_FORM } from "../../design_system/components/admin_table/detail/forms/detail_form_type/detail_form_type";
import {
  ADMINISTRATOR_DETAIL_REGISTER,
  NONE_DETAIL_REGISTER,
} from "../../data_system/register_data_type/register_data_type";
import LongDropdown from "../../design_system/components/long_dropdown/LongDropdown";
import {
  h_px17rate,
  h_px22rate,
  h_px30rate,
  w_px10rate,
  w_px12rate,
  w_px4rate,
  w_px5rate,
  w_px60rate,
} from "../../design_system/size";
import {
  deleteAdministratorByAdministratorId,
  getAdministratorsByPageWithCondition,
  getSubscribePaymentsByPageWithCondition,
} from "../../communication_system/axios_apis/axios_apis";
import {
  CHECK_BOX,
  NAME,
  NUMBER,
  PRICE,
  STRING,
  SUBSCRIBE_CATEGORY,
} from "../../data_system/data_type/data_type";
import {
  rgb_255_255_255,
  rgb_35_40_45,
  rgb_66_139_247,
} from "../../design_system/colors";
import SearchBar from "../../design_system/components/searchbar/SearchBar";
import styled from "styled-components";
import { BodyContentsContext } from "../../data_system/contexts/body_contents_context/body_contents_context";
import produce from "immer";
import {
  subscribe_payment_bodyContents,
  subscribe_payment_headerTitle,
  subscribe_payment_structureInfo,
} from "../../data_system/subscribe_payment_data/subscribe_payment_data";

const SubscribePayment = () => {
  const { bodyContents, setBodyContents } = useContext(BodyContentsContext);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  const [choiceRowIndex, setChoiceRowIndex] = useState(null);
  const [itemOpen, setItemOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);

  const [userDetailDropdownItem, setUserDetailDropdownItem] = useState({
    id: 0,
    title: "이름",
    selected: false,
    key: "name",
    value: 1,
  });

  const [userDetailList, setUserDetailList] = useState([
    {
      id: 0,
      title: "이름",
      selected: false,
      key: "name",
      value: 1,
    },
    {
      id: 1,
      title: "이메일 주소",
      selected: false,
      key: "email",
      value: 2,
    },
    {
      id: 2,
      title: "휴대폰 번호",
      selected: false,
      key: "phone_number",
      value: 3,
    },
  ]);

  const [page, setPage] = useState(1);

  const userDetailResetThenSet = useCallback((item) => {
    setUserDetailDropdownItem(item);
  }, []);

  const onChangeSearchText = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const onSearch = useCallback(async () => {
    const response = await getSubscribePaymentsByPageWithCondition(
      page,
      userDetailDropdownItem.value,
      searchText
    );
    console.log(response);
    let tempBodyContents = [];
    for (let i = 0; i < response.data.payments.length; i++) {
      tempBodyContents.push({
        id: response.data.payments[i].id,
        data: [
          {
            type: NAME,
            data: response.data.payments[i].User.name,
          },
          {
            type: STRING,
            data: response.data.payments[i].User.email,
          },
          {
            type: STRING,
            data: response.data.payments[i].User.phoneNumber,
          },
          {
            type: NUMBER,
            data: response.data.payments[i].User.maxGuideNumber,
          },
          {
            type: STRING,
            data:
              parseInt(response.data.payments[i].productId) === 1 ||
              parseInt(response.data.payments[i].productId) === 3
                ? "스탠다드"
                : "베이직",
          },
          {
            type: PRICE,
            data: response.data.payments[i].User.subscriptionPrice,
          },
        ],
      });
    }
    setBodyContents(tempBodyContents);
    setTotalPages(response.data.totalPages);
  }, [page, userDetailDropdownItem.value, searchText]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await getSubscribePaymentsByPageWithCondition(
        page,
        userDetailDropdownItem.value,
        searchText
      );
      console.log(response);
      let tempBodyContents = [];
      for (let i = 0; i < response.data.payments.length; i++) {
        tempBodyContents.push({
          id: response.data.payments[i].id,
          data: [
            {
              type: NAME,
              data: response.data.payments[i].User.name,
            },
            {
              type: STRING,
              data: response.data.payments[i].User.email,
            },
            {
              type: STRING,
              data: response.data.payments[i].User.phoneNumber,
            },
            {
              type: NUMBER,
              data: response.data.payments[i].User.maxGuideNumber,
            },
            {
              type: STRING,
              data:
                parseInt(response.data.payments[i].productId) === 1 ||
                parseInt(response.data.payments[i].productId) === 3
                  ? "스탠다드"
                  : "베이직",
            },
            {
              type: PRICE,
              data: response.data.payments[i].User.subscriptionPrice,
            },
          ],
        });
      }
      setBodyContents(tempBodyContents);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    }

    fetchData();
  }, [page]);

  if (loading) return <div>loading...</div>;

  return (
    <>
      <>
        <SearchSection>
          <LongDropdown
            list={userDetailList}
            resetThenSet={userDetailResetThenSet}
            index={0}
          ></LongDropdown>
          <div
            style={{
              marginLeft: w_px4rate,
            }}
          >
            <SearchBar
              searchText={searchText}
              onChangeSearchText={onChangeSearchText}
              onSearch={onSearch}
            ></SearchBar>
          </div>
        </SearchSection>
      </>
      <AdminTable
        structureInfo={subscribe_payment_structureInfo}
        headerTitle={subscribe_payment_headerTitle}
        bodyContents={bodyContents}
        DETAIL_DATA_TYPE={SUBSCRIBE_PAYMENT_DETAIL_COMPONENT}
        DETAIL_FORM_TYPE={DETAIL_COMMON_FORM}
        DETAIL_DATA_REGISTER={NONE_DETAIL_REGISTER}
        detail_disable={false}
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        setBodyContents={setBodyContents}
        choiceRowIndex={choiceRowIndex}
        setChoiceRowIndex={setChoiceRowIndex}
        itemOpen={itemOpen}
        setItemOpen={setItemOpen}
        registerOpen={registerOpen}
        setRegisterOpen={setRegisterOpen}
      ></AdminTable>
    </>
  );
};

export default React.memo(SubscribePayment);

const SearchSection = styled.div`
  position: relative;
  display: flex;
  margin-bottom: ${h_px22rate}px;
  z-index: 999;
`;

const MiddleSectionBlackText = styled.div`
  font-size: ${w_px12rate}px;
  color: ${rgb_35_40_45};
  font-family: noto_sans_kr_bold;
`;

const MiddleSectionBlueText = styled.div`
  font-size: ${w_px12rate}px;
  color: ${rgb_66_139_247};
  font-family: noto_sans_kr_bold;
`;

const RegisterBtn = styled.button`
  width: ${w_px60rate}px;
  height: ${h_px30rate}px;
  border: 1px solid ${rgb_66_139_247};
  background-color: ${rgb_66_139_247};
  color: ${rgb_255_255_255};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${w_px12rate}px;
  font-family: noto_sans_kr_bold;
`;

const DeleteBtn = styled.button`
  width: ${w_px60rate}px;
  height: ${h_px30rate}px;
  border: 1px solid ${rgb_66_139_247};
  background-color: ${rgb_255_255_255};
  color: ${rgb_66_139_247};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${w_px12rate}px;
  font-family: noto_sans_kr_bold;
`;

import React, { useEffect, useState } from "react";
import { getMonthlyTotalPrices } from "../../communication_system/axios_apis/axios_apis";
import { table_price_function } from "../../data_system/data_functions/table_price_function/table_price_function";

const PaymentScore = () => {
  const [monthlyTotalPrices, setMonthlyTotalPrices] = useState(null);
  const [totalPrices, setTotalPrices] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      setLoading(true);

      const response = await getMonthlyTotalPrices();
      console.log(response);

      if (response.status === 200) {
        setMonthlyTotalPrices(
          response.data.monthlyTotalPrices.monthlyTotalPrices
        );
        setTotalPrices(response.data.monthlyTotalPrices.totalPrices);
      }

      setLoading(false);
    }

    getData();
  }, []);

  return (
    !loading && (
      <>
        <div>
          <div>총 매출: {table_price_function(totalPrices.totalPrice)}</div>
          <br></br>
          <div>
            {monthlyTotalPrices.map((monthlyTotalPrice) => (
              <div key={monthlyTotalPrice.month}>
                <div>
                  {monthlyTotalPrice.month}월 매출:{" "}
                  {table_price_function(monthlyTotalPrice.totalPrice)}{" "}
                </div>
                <br></br>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  );
};

export default React.memo(PaymentScore);

export function formatBankAccount(bankTypeId, bankAccountNumber) {
  if (
    bankTypeId === undefined ||
    bankTypeId === null ||
    bankAccountNumber === undefined ||
    bankAccountNumber === null
  ) {
    return ["", ""];
  }

  const bankNames = [
    "신한은행",
    "우리은행",
    "카카오뱅크",
    "케이뱅크",
    "토스뱅크",
    "NH농협은행",
    "단위농협",
    "대구은행",
    "수협은행",
    "새마을금고",
    "KB국민은행",
    "SC제일은행",
    "하나은행",
    "한국산업은행",
    "한국씨티은행",
    "광주은행",
    "BNK경남은행",
    "제주은행",
    "중소기업은행",
    "우체국",
  ];

  const bankName = bankNames[bankTypeId - 1];

  const accountNumberGroups = [
    [3, 3, 6],
    [4, 3, 6],
    [4, 2, 7],
    [3, 3, 6],
    [4, 4, 4],
    [3, 4, 4, 2],
    [3, 4, 4, 2],
    [3, 2, 6, 1],
    [4, 4, 4],
    [4, 4, 4, 1],
    [6, 2, 6],
    [3, 2, 6],
    [3, 6, 5],
    [3, 4, 4, 3],
    [3, 5, 3, 2],
    [3, 3, 6],
    [3, 2, 7],
    [2, 2, 6],
    [3, 6, 2, 3],
    [6, 2, 6],
  ];

  const groups = accountNumberGroups[bankTypeId - 1];
  const formattedGroups = [];

  let startIndex = 0;
  for (const length of groups) {
    formattedGroups.push(
      bankAccountNumber.slice(startIndex, startIndex + length)
    );
    startIndex += length;
  }

  const formattedAccountNumber = formattedGroups.join("-");

  return [bankName, formattedAccountNumber];
}

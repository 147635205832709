import React, { useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "../pages/layout/Layout";
import { sidebar_data } from "../data_system/sidebar_data/sidebar_data";
import Login from "../pages/login/Login";

const TotalRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login></Login>}></Route>
        <Route path={"/after-login"} element={<Layout />}>
          {sidebar_data.map((main) => {
            return main.subTitleList.map((sub) => {
              return (
                <Route
                  path={sub["router_url"]}
                  exact={true}
                  element={sub["components"]}
                ></Route>
              );
            });
          })}
        </Route>
      </Routes>
    </Router>
  );
};

export default React.memo(TotalRouter);

import axios from "axios";
import axiosMiddleware from "./axios_middleware/axios_middleware";
import { doogom_com_server_router_url } from "../communication_ips/communication_ips";

export const adminSignUp = async (email, password, name, phoneNumber) => {
  try {
    const response = await axiosMiddleware.post(`/admin-auth/admin-signup`, {
      email,
      password,
      name,
      phoneNumber,
    });
    console.log(response);
    if (response.data.accessToken && response.data.refreshToken) {
      return {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        status: response.status,
      };
    } else {
      console.error("Error during signup: Tokens not provided");
      return null;
    }
  } catch (error) {
    console.log("Error during signup:", error);
    return {
      status: error.response.status,
    };
  }
};

export const adminLoginUser = async (email, password) => {
  try {
    const response = await axios.post(
      `${doogom_com_server_router_url}/admin-login/admin-login`,
      {
        email,
        password,
      }
    );

    if (response.data.accessToken && response.data.refreshToken) {
      return {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        status: response.status,
        user: response.data.user,
      };
    }
  } catch (error) {
    console.log("Error during login:", error.response.status);
    return {
      status: error.response.status,
    };
  }
};

export async function getUsersByPageWithCondition(
  page,
  user_state,
  user_detail,
  search_text
) {
  const response = await axiosMiddleware.get(
    `/admin-user/get-users-by-page-with-condition?page=${page}&limit=10&user_state=${user_state}&user_detail=${user_detail}&search_text=${search_text}`
  );

  return response;
}

export async function getAllUsersForExcel() {
  const response = await axiosMiddleware.get(
    `/admin-user/get-all-users-for-excel`
  );

  return response;
}

export async function getUserById(userId) {
  const response = await axiosMiddleware.get(
    `/admin-user/get-user-by-id?userId=${userId}`
  );

  return response;
}

export async function putUserInformationByAdmin(userId, putDetails) {
  const response = await axiosMiddleware.put(
    `/admin-user/put-user-information-by-admin?userId=${userId}`,
    {
      name: putDetails.name,
      phoneNumber: putDetails.phoneNumber,
      blocked: putDetails.blocked,
      totalSubscriptionEndDate: putDetails.totalSubscriptionEndDate,
    }
  );

  return response;
}

export async function putUserAdminMemoByAdmin(userId, putDetails) {
  const response = await axiosMiddleware.put(
    `/admin-user/put-user-information-by-admin?userId=${userId}`,
    {
      adminMemo: putDetails.adminMemo,
    }
  );

  return response;
}

export async function putProcessUserRefund(userId) {
  const response = await axiosMiddleware.put(
    `/admin-user/put-process-user-refund?userId=${userId}`
  );

  return response;
}

export async function getAdministratorsByPageWithCondition(
  page,
  user_detail,
  search_text
) {
  const response = await axiosMiddleware.get(
    `/admin-administrator/get-administrators-by-page-with-condition?page=${page}&limit=10&user_detail=${user_detail}&search_text=${search_text}`
  );

  return response;
}

export async function getAdministratorById(administratorId) {
  const response = await axiosMiddleware.get(
    `/admin-administrator/get-administrator-by-id?administratorId=${administratorId}`
  );

  return response;
}

export async function putAdministratorInformationByAdmin(
  administratorId,
  putDetails
) {
  const response = await axiosMiddleware.put(
    `/admin-administrator/put-administrator-information-by-admin?administratorId=${administratorId}`,
    {
      name: putDetails.name,
      email: putDetails.email,
      phoneNumber: putDetails.phoneNumber,
    }
  );

  return response;
}

export async function deleteAdministratorByAdministratorId(administratorId) {
  const response = await axiosMiddleware.delete(
    `/admin-administrator/delete-administrator-by-administrator-id?administratorId=${administratorId}`
  );

  return response;
}

export async function getSubscribePaymentsByPageWithCondition(
  page,
  user_detail,
  search_text
) {
  const response = await axiosMiddleware.get(
    `/admin-subscribe-payment/get-subscribe-payments-by-page-with-condition?page=${page}&limit=10&user_detail=${user_detail}&search_text=${search_text}`
  );

  return response;
}

export async function getSubscribePaymentById(paymentId) {
  const response = await axiosMiddleware.get(
    `/admin-subscribe-payment/get-subscribe-payment-by-id?paymentId=${paymentId}`
  );

  return response;
}

export async function getStorePaymentHistoriesByUserId(userId) {
  const response = await axiosMiddleware.post(
    `/admin-store/get-store-payment-histories`,
    {
      userId: userId,
    }
  );

  return response;
}

export async function getStorePaymentHistoriesByUserIdForExcel(
  userId,
  year,
  month
) {
  const response = await axiosMiddleware.post(
    `/admin-store/get-store-payment-histories-for-excel`,
    {
      userId: userId,
      year: year,
      month: month,
    }
  );

  return response;
}

export async function getMonthlyTotalPrices() {
  const response = await axiosMiddleware.get(
    `/admin-statistics/get-monthly-total-prices`
  );

  return response;
}

export async function getStorePaymentsByPageWithCondition(
  page,
  user_detail,
  search_text
) {
  const response = await axiosMiddleware.get(
    `/admin-store/get-payment-histories-by-page-with-condition?page=${page}&limit=10&user_detail=${user_detail}&search_text=${search_text}`
  );

  return response;
}

export async function getStorePaymentById(paymentId) {
  const response = await axiosMiddleware.get(
    `/admin-store/get-payment-history-by-id?paymentId=${paymentId}`
  );

  return response;
}

export async function getVisitorStatsForCurrentYear() {
  const response = await axiosMiddleware.get(
    `/admin-statistics/get-visitor-stats-for-current-year`
  );

  return response;
}

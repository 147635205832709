import React from "react";
import { rgba_66_139_247_0d2 } from "../../colors";
import { w_px312rate } from "../../size";

const DivisionWhiteBlueLine = () => {
  return (
    <div
      style={{
        width: w_px312rate,
        height: 0.5,
        backgroundColor: rgba_66_139_247_0d2,
      }}
    ></div>
  );
};

export default React.memo(DivisionWhiteBlueLine);

import React from "react";
import styled from "styled-components";
import { rgb_66_139_247 } from "../../colors";
import { w_px16rate } from "../../size";

const CheckBox = ({ onClick, checked }) => {
  return (
    <CheckBoxInput onClick={onClick} checked={checked} readOnly></CheckBoxInput>
  );
};

export default React.memo(CheckBox);

const CheckBoxInput = styled.input.attrs({ type: "checkbox" })`
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  width: ${w_px16rate}px;
  height: ${w_px16rate}px;
  border: 1px solid ${rgb_66_139_247};
  border-radius: 0px;
  margin: 0px;
  &::after {
    border: solid #fff;
    border-width: 0 2px 2px 0;
    content: "";
    display: none;
    height: 60%;
    left: 33%;
    position: relative;
    top: 10%;
    transform: rotate(45deg);
    width: 23%;
  }
  &:checked {
    background: ${rgb_66_139_247};
  }
  &:checked::after {
    display: block;
  }
`;

export const DATE = "DATE";
export const NAME = "NAME";
export const STRING = "STRING";
export const MANAGER_MEMO = "MANAGER_MEMO";
export const CHECK_BOX = "CHECK_BOX";
export const HEADER_TITLE = "HEADER_TITLE";
export const HEADER_CHECK_BOX = "HEADER_CHECK_BOX";
export const SUBSCRIBE_STATE = "SUBSCRIBE_STATE";
export const SUBSCRIBE_CATEGORY = "SUBSCRIBE_CATEGORY";
export const PRICE = "PRICE";
export const NUMBER = "NUMBER";

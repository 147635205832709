import { createGlobalStyle } from "styled-components";
import NotoSansKRBold from "./NotoSansKR_Bold.otf";
import NotoSansKRMedium from "./NotoSansKR_Medium.otf";
import NotoSansKRRegular from "./NotoSansKR_Regular.otf";
import NotoSansKRBlack from "./NotoSansKR_Black.otf";
import AppleSDGothicNeoR from "./AppleSDGothicNeo_R.ttf";
import InterRegular from "./Inter_Regular.ttf";
import RobotoBold from "./Roboto_Bold.ttf";
import RobotoMedium from "./Roboto_Medium.ttf";
import RobotoRegular from "./Roboto_Regular.ttf";
import NanumSquareRoundOTFEB from "./NanumSquareRoundOTFEB.otf";

const GlobalStyleFonts = createGlobalStyle`
  @font-face {
    font-family: 'noto_sans_kr_bold';
    font-style: normal;
    src: url(${NotoSansKRBold}) format('opentype');
  }

  @font-face {
    font-family: 'noto_sans_kr_medium';
    font-style: normal;
    src: url(${NotoSansKRMedium}) format('opentype');
  }

  @font-face {
    font-family: 'noto_sans_kr_regular';
    font-style: normal;
    src: url(${NotoSansKRRegular}) format('opentype');
  }

  @font-face {
    font-family: 'noto_sans_kr_black';
    font-style: normal;
    src: url(${NotoSansKRBlack}) format('opentype');
  }

  @font-face {
    font-family: 'apple_sd_gothic_neo_regular';
    font-style: normal;
    src: url(${AppleSDGothicNeoR}) format('truetype');
  }

  @font-face {
    font-family: 'inter_regular';
    font-style: normal;
    src: url(${InterRegular}) format('truetype');
  }

  @font-face {
    font-family: 'roboto_bold';
    font-style: normal;
    src: url(${RobotoBold}) format('truetype');
  }

  @font-face {
    font-family: 'roboto_medium';
    font-style: normal;
    src: url(${RobotoMedium}) format('truetype');
  }

  @font-face {
    font-family: 'roboto_regular';
    font-style: normal;
    src: url(${RobotoRegular}) format('truetype');
  }

  @font-face {
    font-family: 'nanum_square_round_otf_eb';
    font-style: normal;
    src: url(${NanumSquareRoundOTFEB}) format('opentype');
  }
`;

export default GlobalStyleFonts;

import React from "react";
import {
  h_px20rate,
  w_px16rate,
  w_px18rate,
  w_px20rate,
  w_px40rate,
} from "../size";
import arrow_down_blue_1x from "./arrow_down_blue/arrow_down_blue_1x.png";
import arrow_up_blue_1x from "./arrow_up_blue/arrow_up_blue_1x.png";
import search_white_1x from "./search_white/search_white_1x.png";
import square_and_pencil_1x from "./square_and_pencil/square_and_pencil_1x.png";
import arrow_forward_gray_1x from "./arrow_forward_gray/arrow_forward_gray_1x.png";
import arrow_backward_gray_1x from "./arrow_backward_gray/arrow_backward_gray_1x.png";
import arrow_backward_white_1x from "./arrow_backward_white/arrow_backward_white_1x.png";
import white_plus_1x from "./white_plus/white_plus_1x.png";
import x_mark_white_1x from "./x_mark_white/x_mark_white_1x.png";
import x_mark_white_2x from "./x_mark_white/x_mark_white_2x.png";
import x_mark_blue_1x from "./x_mark_blue/x_mark_blue_1x.png";
import calendar_blue_1x from "./calendar_blue/calendar_blue_1x.png";
import x_mark_gray_1x from "./x_mark_gray/x_mark_gray_1x.png";

export const ArrowDownBlue1x = () => {
  return (
    <img
      src={arrow_down_blue_1x}
      alt={""}
      width={`${w_px18rate}px`}
      height={`${w_px18rate}px`}
      style={{
        margin: 0,
        padding: 0,
      }}
    ></img>
  );
};
export const ArrowUpBlue1x = () => {
  return (
    <img
      src={arrow_up_blue_1x}
      alt={""}
      width={`${w_px18rate}px`}
      height={`${w_px18rate}px`}
      style={{
        margin: 0,
        padding: 0,
      }}
    ></img>
  );
};
export const SearchWhite1x = () => {
  return (
    <img
      src={search_white_1x}
      alt=""
      width={`${w_px18rate}px`}
      height={`${w_px18rate}px`}
      style={{
        margin: 0,
        padding: 0,
      }}
    ></img>
  );
};
export const SquareAndPencil1x = () => {
  return (
    <img
      src={square_and_pencil_1x}
      alt={""}
      width={`${w_px20rate}px`}
      height={`${w_px20rate}px`}
      style={{
        margin: 0,
        padding: 0,
      }}
    ></img>
  );
};
export const ArrowForwardGray1x = () => {
  return (
    <img
      src={arrow_forward_gray_1x}
      alt={""}
      width={`${w_px18rate}px`}
      height={`${w_px18rate}px`}
      style={{
        margin: 0,
        padding: 0,
      }}
    ></img>
  );
};
export const ArrowBackwardGray1x = () => {
  return (
    <img
      src={arrow_backward_gray_1x}
      alt={""}
      width={`${w_px18rate}px`}
      height={`${w_px18rate}px`}
      style={{
        margin: 0,
        padding: 0,
      }}
    ></img>
  );
};
export const ArrowBackwardWhite1x = () => {
  return (
    <img
      src={arrow_backward_white_1x}
      alt={""}
      width={`${w_px40rate}px`}
      height={`${w_px40rate}px`}
      style={{
        margin: 0,
        padding: 0,
      }}
    ></img>
  );
};
export const WhitePlus1x = () => {
  return (
    <img
      src={white_plus_1x}
      alt={""}
      width={`${w_px18rate}px`}
      height={`${w_px18rate}px`}
      style={{
        margin: 0,
        padding: 0,
      }}
    ></img>
  );
};
export const XMarkWhite1x = () => {
  return (
    <img
      src={x_mark_white_1x}
      alt={""}
      width={`${w_px16rate}px`}
      height={`${w_px16rate}px`}
      style={{
        margin: 0,
        padding: 0,
      }}
    ></img>
  );
};
export const XMarkWhite2x = () => {
  return (
    <img
      src={x_mark_white_2x}
      alt={""}
      width={`${w_px40rate}px`}
      height={`${w_px40rate}px`}
      style={{
        margin: 0,
        padding: 0,
      }}
    ></img>
  );
};
export const XMarkBlue1x = () => {
  return (
    <img
      src={x_mark_blue_1x}
      alt={""}
      width={`${w_px16rate}px`}
      height={`${w_px16rate}px`}
      style={{
        margin: 0,
        padding: 0,
      }}
    ></img>
  );
};
export const CalendarBlue1x = () => {
  return (
    <img
      src={calendar_blue_1x}
      alt={""}
      width={`${h_px20rate}px`}
      height={`${h_px20rate}px`}
      style={{
        margin: 0,
        padding: 0,
      }}
    ></img>
  );
};
export const XMarkGray1x = () => {
  return (
    <img
      src={x_mark_gray_1x}
      alt={""}
      width={`${w_px18rate}px`}
      height={`${w_px18rate}px`}
      style={{
        margin: 0,
        padding: 0,
      }}
    ></img>
  );
};

import React from "react";
import styled from "styled-components";
import { w_px11rate } from "../../size";

const ContentText = ({ children }) => {
  return <Text>{children}</Text>;
};

export default React.memo(ContentText);

const Text = styled.div`
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_medium;
`;

import {
  storePayment_router_url,
  administrator_router_url,
  paymentScore_router_url,
  viewScore_router_url,
  subscribePayment_router_url,
  user_router_url,
} from "../../communication_system/inner_router_url/inner_router_url";
import StorePayment from "../../pages/store_payment/StorePayment";
import Administrator from "../../pages/administrator/Administrator";
import ViewScore from "../../pages/view_score/ViewScore";
import PaymentScore from "../../pages/payment_score/PaymentScore";
import SubscribePayment from "../../pages/subscribe_payment/SubscribePayment";
import User from "../../pages/user/User";

export const sidebar_data = [
  {
    mainId: 0,
    mainTitle: "이용자관리",
    subTitleList: [
      {
        subId: 0,
        name: "회원",
        is_active: 1,
        router_url: user_router_url,
        components: <User></User>,
      },
      {
        subId: 1,
        name: "관리자",
        is_active: 0,
        router_url: administrator_router_url,
        components: <Administrator></Administrator>,
      },
    ],
  },
  {
    mainId: 1,
    mainTitle: "결제내역",
    subTitleList: [
      {
        subId: 0,
        name: "정기결제",
        is_active: 0,
        router_url: subscribePayment_router_url,
        components: <SubscribePayment></SubscribePayment>,
      },
    ],
  },
  {
    mainId: 2,
    mainTitle: "스토어",
    subTitleList: [
      {
        subId: 0,
        name: "결제내역",
        is_active: 0,
        router_url: storePayment_router_url,
        components: <StorePayment></StorePayment>,
      },
    ],
  },
  {
    mainId: 3,
    mainTitle: "통계",
    subTitleList: [
      {
        subId: 0,
        name: "방문통계",
        is_active: 0,
        router_url: viewScore_router_url,
        components: <ViewScore></ViewScore>,
      },
      {
        subId: 1,
        name: "매출통계",
        is_active: 0,
        router_url: paymentScore_router_url,
        components: <PaymentScore></PaymentScore>,
      },
    ],
  },
];

import React from "react";
import styled from "styled-components";
import { rgb_0_0_0, rgb_255_255_255 } from "../../colors";
import { SquareAndPencil1x } from "../../icons/icons";
import {
  h_px10rate,
  h_px8rate,
  w_px160rate,
  w_px4rate,
  w_px8rate,
} from "../../size";

const TooltipCard = ({ contents }) => {
  return (
    <Card>
      <TooltipText>
        <SquareAndPencil1x></SquareAndPencil1x>
      </TooltipText>
      <TooltipBox>{contents}</TooltipBox>
    </Card>
  );
};

export default React.memo(TooltipCard);

const Card = styled.div`
  position: relative;
`;

const TooltipBox = styled.div`
  position: absolute;
  top: calc(100% + ${h_px10rate});
  visibility: hidden;
  color: ${rgb_0_0_0};
  background-color: ${rgb_255_255_255};
  width: ${w_px160rate}px;
  padding: ${h_px8rate}px ${w_px8rate}px;
  border-radius: ${w_px4rate}px;
  box-shadow: 1px 2px ${w_px4rate}px;
  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;
`;

const TooltipText = styled.div`
  cursor: pointer;
  &:hover + ${TooltipBox} {
    visibility: visible;
  }
`;

export const total_width = 1466;
export const total_height = 977;

//width
export const w_px2rate = (2 / 1466) * total_width;
export const w_px3rate = (3 / 1466) * total_width;
export const w_px4rate = (4 / 1466) * total_width;
export const w_px5rate = (5 / 1466) * total_width;
export const w_px6rate = (6 / 1466) * total_width;
export const w_px7rate = (7 / 1466) * total_width;
export const w_px8rate = (8 / 1466) * total_width;
export const w_px8d6rate = (8.6 / 1466) * total_width;
export const w_px9rate = (9 / 1466) * total_width;
export const w_px10rate = (10 / 1466) * total_width;
export const w_px11rate = (11 / 1466) * total_width;
export const w_px12rate = (12 / 1466) * total_width;
export const w_px13rate = (13 / 1466) * total_width;
export const w_px14rate = (14 / 1466) * total_width;
export const w_px15rate = (15 / 1466) * total_width;
export const w_px16rate = (16 / 1466) * total_width;
export const w_px17rate = (17 / 1466) * total_width;
export const w_px18rate = (18 / 1466) * total_width;
export const w_px18d6rate = (18.6 / 1466) * total_width;
export const w_px19rate = (19 / 1466) * total_width;
export const w_px20rate = (20 / 1466) * total_width;
export const w_px22rate = (22 / 1466) * total_width;
export const w_px22d4rate = (22.4 / 1466) * total_width;
export const w_px25rate = (25 / 1466) * total_width;
export const w_px26rate = (26 / 1466) * total_width;
export const w_px28rate = (28 / 1466) * total_width;
export const w_px29rate = (29 / 1466) * total_width;
export const w_px31rate = (31 / 1466) * total_width;
export const w_px31d2rate = (31.2 / 1466) * total_width;
export const w_px31d5rate = (31.5 / 1466) * total_width;
export const w_px32rate = (32 / 1466) * total_width;
export const w_px33rate = (33 / 1466) * total_width;
export const w_px35rate = (35 / 1466) * total_width;
export const w_px36d7rate = (36.7 / 1466) * total_width;
export const w_px36d9rate = (36.9 / 1466) * total_width;
export const w_px40rate = (40 / 1466) * total_width;
export const w_px41rate = (41 / 1466) * total_width;
export const w_px43rate = (43 / 1466) * total_width;
export const w_px45rate = (45 / 1466) * total_width;
export const w_px47rate = (47 / 1466) * total_width;
export const w_px48rate = (48 / 1466) * total_width;
export const w_px49rate = (49 / 1466) * total_width;
export const w_px50rate = (50 / 1466) * total_width;
export const w_px50d6rate = (50.6 / 1466) * total_width;
export const w_px51rate = (51 / 1466) * total_width;
export const w_px52rate = (52 / 1466) * total_width;
export const w_px53rate = (53 / 1466) * total_width;
export const w_px54rate = (54 / 1466) * total_width;
export const w_px55rate = (55 / 1466) * total_width;
export const w_px57rate = (57 / 1466) * total_width;
export const w_px57d3rate = (57.3 / 1466) * total_width;
export const w_px58rate = (58 / 1466) * total_width;
export const w_px58d8rate = (58.8 / 1466) * total_width;
export const w_px59rate = (59 / 1466) * total_width;
export const w_px59d1rate = (59.1 / 1466) * total_width;
export const w_px60rate = (60 / 1466) * total_width;
export const w_px61rate = (61 / 1466) * total_width;
export const w_px62rate = (62 / 1466) * total_width;
export const w_px64rate = (64 / 1466) * total_width;
export const w_px66rate = (66 / 1466) * total_width;
export const w_px69rate = (69 / 1466) * total_width;
export const w_px70rate = (70 / 1466) * total_width;
export const w_px71rate = (71 / 1466) * total_width;
export const w_px71d8rate = (71.8 / 1466) * total_width;
export const w_px72rate = (72 / 1466) * total_width;
export const w_px74rate = (74 / 1466) * total_width;
export const w_px76rate = (76 / 1466) * total_width;
export const w_px78rate = (78 / 1466) * total_width;
export const w_px78d8rate = (78.8 / 1466) * total_width;
export const w_px80rate = (80 / 1466) * total_width;
export const w_px82rate = (82 / 1466) * total_width;
export const w_px83d8rate = (83.8 / 1466) * total_width;
export const w_px85rate = (85 / 1466) * total_width;
export const w_px86rate = (86 / 1466) * total_width;
export const w_px87rate = (87 / 1466) * total_width;
export const w_px88rate = (88 / 1466) * total_width;
export const w_px89d7rate = (89.7 / 1466) * total_width;
export const w_px90rate = (90 / 1466) * total_width;
export const w_px91rate = (91 / 1466) * total_width;
export const w_px93rate = (93 / 1466) * total_width;
export const w_px96rate = (96 / 1466) * total_width;
export const w_px97rate = (97 / 1466) * total_width;
export const w_px98rate = (98 / 1466) * total_width;
export const w_px100rate = (100 / 1466) * total_width;
export const w_px103rate = (103 / 1466) * total_width;
export const w_px104d2rate = (104.2 / 1466) * total_width;
export const w_px104d6rate = (104.6 / 1466) * total_width;
export const w_px105rate = (105 / 1466) * total_width;
export const w_px107rate = (107 / 1466) * total_width;
export const w_px108rate = (108 / 1466) * total_width;
export const w_px108d8rate = (108.8 / 1466) * total_width;
export const w_px111rate = (111 / 1466) * total_width;
export const w_px113rate = (113 / 1466) * total_width;
export const w_px114d6rate = (114.6 / 1466) * total_width;
export const w_px116rate = (116 / 1466) * total_width;
export const w_px119rate = (119 / 1466) * total_width;
export const w_px120rate = (120 / 1466) * total_width;
export const w_px121rate = (121 / 1466) * total_width;
export const w_px123rate = (123 / 1466) * total_width;
export const w_px124d6rate = (124.6 / 1466) * total_width;
export const w_px125rate = (125 / 1466) * total_width;
export const w_px128rate = (128 / 1466) * total_width;
export const w_px129rate = (129 / 1466) * total_width;
export const w_px131rate = (131 / 1466) * total_width;
export const w_px133rate = (133 / 1466) * total_width;
export const w_px134rate = (134 / 1466) * total_width;
export const w_px136rate = (136 / 1466) * total_width;
export const w_px138rate = (138 / 1466) * total_width;
export const w_px142rate = (142 / 1466) * total_width;
export const w_px143rate = (143 / 1466) * total_width;
export const w_px144rate = (144 / 1466) * total_width;
export const w_px145rate = (145 / 1466) * total_width;
export const w_px146rate = (146 / 1466) * total_width;
export const w_px156rate = (156 / 1466) * total_width;
export const w_px160rate = (160 / 1466) * total_width;
export const w_px161rate = (161 / 1466) * total_width;
export const w_px162rate = (162 / 1466) * total_width;
export const w_px164rate = (164 / 1466) * total_width;
export const w_px173rate = (173 / 1466) * total_width;
export const w_px179rate = (179 / 1466) * total_width;
export const w_px184rate = (184 / 1466) * total_width;
export const w_px185rate = (185 / 1466) * total_width;
export const w_px189rate = (189 / 1466) * total_width;
export const w_px191rate = (191 / 1466) * total_width;
export const w_px198rate = (198 / 1466) * total_width;
export const w_px204rate = (204 / 1466) * total_width;
export const w_px208rate = (208 / 1466) * total_width;
export const w_px210rate = (210 / 1466) * total_width;
export const w_px214rate = (214 / 1466) * total_width;
export const w_px215rate = (215 / 1466) * total_width;
export const w_px217rate = (217 / 1466) * total_width;
export const w_px218rate = (218 / 1466) * total_width;
export const w_px219rate = (219 / 1466) * total_width;
export const w_px220rate = (220 / 1466) * total_width;
export const w_px227rate = (227 / 1466) * total_width;
export const w_px229rate = (229 / 1466) * total_width;
export const w_px230rate = (230 / 1466) * total_width;
export const w_px231rate = (231 / 1466) * total_width;
export const w_px232rate = (232 / 1466) * total_width;
export const w_px236rate = (236 / 1466) * total_width;
export const w_px237rate = (237 / 1466) * total_width;
export const w_px242rate = (242 / 1466) * total_width;
export const w_px249rate = (249 / 1466) * total_width;
export const w_px257rate = (257 / 1466) * total_width;
export const w_px279rate = (279 / 1466) * total_width;
export const w_px299rate = (299 / 1466) * total_width;
export const w_px308rate = (308 / 1466) * total_width;
export const w_px312rate = (312 / 1466) * total_width;
export const w_px322rate = (322 / 1466) * total_width;
export const w_px323rate = (323 / 1466) * total_width;
export const w_px362rate = (362 / 1466) * total_width;
export const w_px378rate = (378 / 1466) * total_width;
export const w_px429rate = (429 / 1466) * total_width;
export const w_px430rate = (430 / 1466) * total_width;
export const w_px455rate = (455 / 1466) * total_width;
export const w_px477rate = (477 / 1466) * total_width;
export const w_px500rate = (500 / 1466) * total_width;
export const w_px535rate = (535 / 1466) * total_width;
export const w_px864rate = (864 / 1466) * total_width;
export const w_px1226rate = (1226 / 1466) * total_width;
export const w_px1305d5rate = (1305.5 / 1466) * total_width;

//height
export const h_px1rate = (1 / 977) * total_height;
export const h_px2rate = (2 / 977) * total_height;
export const h_px3rate = (3 / 977) * total_height;
export const h_px4rate = (4 / 977) * total_height;
export const h_px5rate = (5 / 977) * total_height;
export const h_px6rate = (6 / 977) * total_height;
export const h_px7rate = (7 / 977) * total_height;
export const h_px8rate = (8 / 977) * total_height;
export const h_px9rate = (9 / 977) * total_height;
export const h_px10rate = (10 / 977) * total_height;
export const h_px12rate = (12 / 977) * total_height;
export const h_px13rate = (13 / 977) * total_height;
export const h_px14rate = (14 / 977) * total_height;
export const h_px15rate = (15 / 977) * total_height;
export const h_px16rate = (16 / 977) * total_height;
export const h_px17rate = (17 / 977) * total_height;
export const h_px18rate = (18 / 977) * total_height;
export const h_px20rate = (20 / 977) * total_height;
export const h_px21rate = (21 / 977) * total_height;
export const h_px22rate = (22 / 977) * total_height;
export const h_px25rate = (25 / 977) * total_height;
export const h_px28rate = (28 / 977) * total_height;
export const h_px29rate = (29 / 977) * total_height;
export const h_px30rate = (30 / 977) * total_height;
export const h_px31rate = (31 / 977) * total_height;
export const h_px32rate = (32 / 977) * total_height;
export const h_px35rate = (35 / 977) * total_height;
export const h_px36rate = (36 / 977) * total_height;
export const h_px38rate = (38 / 977) * total_height;
export const h_px40rate = (40 / 977) * total_height;
export const h_px41rate = (41 / 977) * total_height;
export const h_px42rate = (42 / 977) * total_height;
export const h_px45d5rate = (45.5 / 977) * total_height;
export const h_px51rate = (51 / 977) * total_height;
export const h_px52rate = (52 / 977) * total_height;
export const h_px53rate = (53 / 977) * total_height;
export const h_px54rate = (54 / 977) * total_height;
export const h_px55rate = (55 / 977) * total_height;
export const h_px58rate = (58 / 977) * total_height;
export const h_px59rate = (59 / 977) * total_height;
export const h_px60rate = (60 / 977) * total_height;
export const h_px62d5rate = (62.5 / 977) * total_height;
export const h_px64rate = (64 / 977) * total_height;
export const h_px77rate = (77 / 977) * total_height;
export const h_px80rate = (80 / 977) * total_height;
export const h_px88rate = (88 / 977) * total_height;
export const h_px92rate = (92 / 977) * total_height;
export const h_px100rate = (100 / 977) * total_height;
export const h_px120rate = (120 / 977) * total_height;
export const h_px131rate = (131 / 977) * total_height;
export const h_px159rate = (159 / 977) * total_height;
export const h_px170rate = (170 / 977) * total_height;
export const h_px191rate = (191 / 977) * total_height;
export const h_px224rate = (224 / 977) * total_height;
export const h_px231rate = (231 / 977) * total_height;
export const h_px262rate = (262 / 977) * total_height;
export const h_px439rate = (439 / 977) * total_height;
export const h_px454rate = (454 / 977) * total_height;
export const h_px522d5rate = (522.5 / 977) * total_height;
export const h_px564rate = (564 / 977) * total_height;
export const h_px631rate = (631 / 977) * total_height;
export const h_px671d5rate = (671.5 / 977) * total_height;
export const h_px735d5rate = (735.5 / 977) * total_height;
export const h_px849rate = (849 / 977) * total_height;
export const h_px899rate = (899 / 977) * total_height;

import React, { useCallback, useState } from "react";
import {
  email_check,
  phone_no_check,
} from "../../../../../../data_system/validation_check/validation_check";
import {
  h_px12rate,
  h_px14rate,
  h_px16rate,
  h_px25rate,
  w_px113rate,
  w_px11rate,
  w_px123rate,
  w_px134rate,
  w_px144rate,
  w_px25rate,
  w_px26rate,
  w_px8rate,
} from "../../../../../size";
import DetailTextInput from "../../../../detail_text_input/DetailTextInput";
import ListName from "../../../../list_name/ListName";
import PinkBtn from "../../../../pink_btn/PinkBtn";
import SubTitle from "../../../../sub_title/SubTitle";
import WhiteBtn from "../../../../white_btn/WhiteBtn";
import { v4 as uuidv4 } from "uuid";
import { adminSignUp } from "../../../../../../communication_system/axios_apis/axios_apis";
import {
  CHECK_BOX,
  NAME,
  STRING,
} from "../../../../../../data_system/data_type/data_type";

const AdministratorDetailRegister = ({ setBodyContents }) => {
  const [name, setName] = useState("");

  const onChangeName = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const [adminId, setAdminId] = useState("");

  const onChangeAdminId = useCallback((e) => {
    setAdminId(e.target.value);
  }, []);

  const [password, setPassword] = useState("");

  const onChangePassword = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  const [phoneNo, setPhoneNo] = useState("");

  const onChangePhoneNo = useCallback((e) => {
    setPhoneNo(e.target.value);
  }, []);

  return (
    <div>
      <div
        style={{
          paddingTop: h_px25rate,
          paddingLeft: w_px25rate,
        }}
      >
        <div
          style={{
            marginBottom: h_px14rate,
          }}
        >
          <SubTitle>관리자 정보</SubTitle>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>이름</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <DetailTextInput
              width={w_px113rate}
              height={h_px16rate}
              font_size={w_px11rate}
              placeholder={"이름을 입력해주세요."}
              value={name}
              onChange={onChangeName}
            ></DetailTextInput>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>이메일</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <DetailTextInput
              width={w_px123rate}
              height={h_px16rate}
              font_size={w_px11rate}
              placeholder={"이메일을 입력해주세요."}
              value={adminId}
              onChange={onChangeAdminId}
            ></DetailTextInput>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>비밀번호</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <DetailTextInput
              width={w_px134rate}
              height={h_px16rate}
              font_size={w_px11rate}
              placeholder={"비밀번호를 입력해주세요."}
              value={password}
              onChange={onChangePassword}
            ></DetailTextInput>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>휴대폰번호</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <DetailTextInput
              width={w_px144rate}
              height={h_px16rate}
              font_size={w_px11rate}
              placeholder={"휴대폰번호를 입력해주세요."}
              value={phoneNo}
              onChange={onChangePhoneNo}
            ></DetailTextInput>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <WhiteBtn>취소</WhiteBtn>
          <div
            style={{
              marginLeft: w_px8rate,
            }}
          >
            <PinkBtn
              onClick={async () => {
                if (
                  name !== "" &&
                  adminId !== "" &&
                  email_check.test(adminId) &&
                  password !== "" &&
                  phoneNo !== "" &&
                  phone_no_check.test(phoneNo)
                ) {
                  console.log("등록");
                  const response = await adminSignUp(
                    adminId,
                    password,
                    name,
                    phoneNo
                  );

                  console.log(response);
                  if (response.status === 201) {
                    alert("등록되었습니다.");
                    setBodyContents((prevContents) => {
                      const newId = uuidv4();

                      const newData = {
                        id: newId,
                        data: [
                          {
                            type: CHECK_BOX,
                            data: name,
                          },
                          {
                            type: NAME,
                            data: name,
                          },
                          {
                            type: STRING,
                            data: adminId,
                          },
                          {
                            type: STRING,
                            data: phoneNo,
                          },
                        ],
                      };

                      return [...prevContents, newData];
                    });
                  }

                  if (response.status === 400) {
                    alert("이미 존재하는 이메일입니다.");
                  }
                }
              }}
            >
              등록
            </PinkBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AdministratorDetailRegister);

import React from "react";
import styled from "styled-components";
import { rgba_66_139_247_0d2, rgb_66_139_247 } from "../../colors";
import { w_px15rate } from "../../size";

const RadioBtn = ({ onClick, checked }) => {
  return <RadioInput onClick={onClick} checked={checked} readOnly></RadioInput>;
};

export default React.memo(RadioBtn);

const RadioInput = styled.input.attrs({ type: "radio" })`
  -webkit-appearance: none;
  width: ${w_px15rate}px;
  height: ${w_px15rate}px;
  border: 1px solid ${rgba_66_139_247_0d2};
  border-radius: 50%;
  margin: 0px;
  outline: none;
  &:before {
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }
  &:checked:before {
    background: ${rgb_66_139_247};
  }
`;

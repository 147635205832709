import moment from "moment";

export const table_date_function = (date) => {
  if (date === null) {
    return 0;
  }

  return (
    moment.utc(date).format("YYYY.MM.DD.") +
    "(" +
    moment.utc(date).format("dd").slice(0, 1) +
    ")" +
    " " +
    moment.utc(date).format("HH:mm")
  );
};

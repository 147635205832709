import styled from "styled-components";
import React from "react";
import { w_px25rate } from "../../size";

const UserName = ({ children }) => {
  return <Name>{children}</Name>;
};

export default React.memo(UserName);

const Name = styled.div`
  font-size: ${w_px25rate}px;
  font-family: noto_sans_kr_bold;
`;

import React, { useState, useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import {
  h_px14rate,
  h_px16rate,
  h_px170rate,
  h_px31rate,
  h_px35rate,
  h_px59rate,
  h_px8rate,
  total_width,
  w_px11rate,
  w_px1305d5rate,
  w_px13rate,
  w_px160rate,
  w_px18rate,
  w_px25rate,
  w_px26rate,
  w_px40rate,
  w_px8rate,
} from "../../design_system/size";
import {
  rgba_66_139_247_0d1,
  rgb_0_0_0,
  rgb_121_121_121,
  rgb_131_131_131,
  rgb_205_205_205,
  rgb_210_210_210,
  rgb_229_229_229,
  rgb_255_255_255,
  rgb_66_139_247,
} from "../../design_system/colors";
import { Link, Outlet } from "react-router-dom";
import produce from "immer";
import { sidebar_data } from "../../data_system/sidebar_data/sidebar_data";
import AdminMemoModal from "../../design_system/components/admin_memo_modal/AdminMemoModal";
import AdminMemoModalContext from "../../data_system/contexts/modal_context/admin_memo_modal_context";
import AddScheduleModalContext from "../../data_system/contexts/modal_context/add_schedule_modal_context";
import AddScheduleModal from "../../design_system/components/add_schedule_modal/AddScheduleModal";
import DeleteModalContext from "../../data_system/contexts/modal_context/delete_modal_context";
import DeleteModal from "../../design_system/components/delete_modal/DeleteModal";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../data_system/contexts/auth_context/auth_context";

const Layout = () => {
  const { isLoggedIn, logout } = useAuth();

  const { admin_memo_modal_state } = useContext(AdminMemoModalContext);
  const { add_schedule_modal_state } = useContext(AddScheduleModalContext);
  const { delete_modal_state } = useContext(DeleteModalContext);

  let navigate = useNavigate();
  let location = useLocation();

  const onClickLogout = () => {
    logout();
  };

  const [sideBar, setSideBar] = useState(sidebar_data);

  const selectSubSideBar = useCallback(
    (mainIndex, subIndex) => {
      setSideBar(
        produce(sideBar, (sideBarDraft) => {
          for (let i = 0; i < sideBarDraft.length; i++) {
            for (let j = 0; j < sideBarDraft[i].subTitleList.length; j++) {
              if (mainIndex === i && subIndex === j) {
                sideBarDraft[i].subTitleList[j].is_active = 1;
              } else {
                sideBarDraft[i].subTitleList[j].is_active = 0;
              }
            }
          }
        })
      );
    },
    [sideBar]
  );

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/", { state: { from: location.pathname } });
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div style={{ width: "100%", height: "100vh", overflow: "auto" }}>
      <div
        style={{
          width: "100%",
          overflowX: "auto",
        }}
      >
        {admin_memo_modal_state.modalVisible && (
          <AdminMemoModal></AdminMemoModal>
        )}
        {delete_modal_state.modalVisible && <DeleteModal></DeleteModal>}
        {add_schedule_modal_state.modalVisible && (
          <AddScheduleModal></AddScheduleModal>
        )}
        <TotalFrame>
          <MainHeader>
            <MainHeaderRightSide>관리자센터</MainHeaderRightSide>
            <MainHeaderLeftSide>
              <MainHeaderLogoutBtn type="button" onClick={onClickLogout}>
                로그아웃
              </MainHeaderLogoutBtn>
            </MainHeaderLeftSide>
          </MainHeader>
          <MainBody>
            <MainLeftSide>
              {sideBar.map((main, mainIndex) => {
                return (
                  <div key={mainIndex}>
                    <MainLeftSideTitle>{main["mainTitle"]}</MainLeftSideTitle>
                    {main["subTitleList"].map((sub, subIndex) => {
                      return (
                        <div key={subIndex}>
                          {subIndex !== main["subTitleList"].length - 1 ? (
                            <MainLeftSideSubTitleLink
                              to={sub["router_url"]}
                              is_active={sub["is_active"]}
                            >
                              <MainLeftSideSubTitle
                                onClick={() => {
                                  selectSubSideBar(mainIndex, subIndex);
                                }}
                                is_active={sub["is_active"]}
                              >
                                {sub["name"]}
                              </MainLeftSideSubTitle>
                            </MainLeftSideSubTitleLink>
                          ) : (
                            <div>
                              <MainLeftSideSubTitleLink
                                to={sub["router_url"]}
                                is_active={sub["is_active"]}
                              >
                                <MainLeftSideSubTitleLast
                                  onClick={() => {
                                    selectSubSideBar(mainIndex, subIndex);
                                  }}
                                  is_active={sub["is_active"]}
                                >
                                  {sub["name"]}
                                </MainLeftSideSubTitleLast>
                              </MainLeftSideSubTitleLink>
                              {mainIndex !== sideBar.length - 1 && (
                                <MainLeftSideDivider></MainLeftSideDivider>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <MainLeftSideDetailInfo>
                <div>두곰</div>
                <div>사업자번호 444-14-02165</div>
              </MainLeftSideDetailInfo>
            </MainLeftSide>
            <MainRightSide>
              {sideBar.map((main) => {
                return main.subTitleList.map((sub, subIndex) => {
                  return (
                    sub.is_active === 1 && (
                      <MainRightSideTitle key={subIndex}>
                        {sub.name}
                      </MainRightSideTitle>
                    )
                  );
                });
              })}
              <Outlet></Outlet>
            </MainRightSide>
          </MainBody>
        </TotalFrame>
      </div>
    </div>
  );
};

export default React.memo(Layout);

const TotalFrame = styled.div`
  width: ${total_width}px;
  border: ${1}px solid ${rgb_121_121_121};
`;

const MainHeader = styled.div`
  border-bottom: ${1}px solid ${rgb_229_229_229};
  padding: ${w_px25rate}px;
  align-items: center;
  justify-content: space-between;
  display: flex;
`;

const MainHeaderRightSide = styled.div`
  font-family: noto_sans_kr_bold;
  font-size: ${w_px18rate}px;
  color: ${rgb_0_0_0};
`;

const MainHeaderLeftSide = styled.div`
  font-family: noto_sans_kr_regular;
  font-size: ${w_px11rate}px;
  display: flex;
  align-items: center;
`;

const MainHeaderLogoutBtn = styled.button`
  border-color: ${rgb_255_255_255};
  background-color: ${rgb_255_255_255};
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_regular;
  border-width: 0;
  padding: 0;
  margin: 0;
`;

const MainHeaderDivider = styled.div`
  margin-left: ${w_px13rate}px;
  margin-right: ${w_px13rate}px;
  height: ${h_px16rate}px;
  width: 1px;
  background-color: ${rgb_210_210_210};
`;

const MainBody = styled.div`
  display: flex;
`;

const MainLeftSide = styled.div`
  display: block;
  width: ${w_px160rate}px;
  border-right: ${1}px solid ${rgb_229_229_229};
`;

const MainLeftSideTitle = styled.div`
  padding-top: ${h_px14rate}px;
  padding-left: ${w_px25rate}px;
  padding-bottom: ${h_px14rate}px;
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_bold;
  color: ${rgb_121_121_121};
`;

const MainLeftSideSubTitle = styled.div`
  padding-top: ${h_px8rate}px;
  padding-left: ${w_px25rate}px;
  padding-bottom: ${h_px8rate}px;
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_bold;
  background-color: ${(props) =>
    props.is_active === 1 ? rgba_66_139_247_0d1 : rgb_255_255_255};
`;

const MainLeftSideSubTitleLast = styled.div`
  padding-top: ${h_px8rate}px;
  padding-left: ${w_px25rate}px;
  padding-bottom: ${h_px14rate}px;
  font-size: ${w_px11rate}px;
  font-family: noto_sans_kr_bold;
  background-color: ${(props) =>
    props.is_active === 1 ? rgba_66_139_247_0d1 : rgb_255_255_255};
`;

const MainLeftSideSubTitleLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => (props.is_active === 1 ? rgb_66_139_247 : rgb_0_0_0)};
`;

const MainLeftSideDivider = styled.div`
  height: 1px;
  background-color: ${rgb_229_229_229};
`;

const MainLeftSideDetailInfo = styled.div`
  font-size: ${w_px8rate}px;
  padding-left: ${w_px25rate}px;
  padding-right: ${w_px25rate}px;
  margin-top: ${h_px170rate}px;
  margin-bottom: ${h_px59rate}px;
  color: ${rgb_205_205_205};
  font-family: noto_sans_kr_bold;
`;

const MainRightSide = styled.div`
  width: ${w_px1305d5rate}px;
  padding-top: ${h_px31rate}px;
  padding-left: ${w_px40rate}px;
  padding-right: ${w_px40rate}px;
`;

const MainRightSideTitle = styled.div`
  font-size: ${w_px26rate}px;
  font-family: noto_sans_kr_bold;
  color: ${rgb_0_0_0};
  margin-bottom: ${h_px35rate}px;
`;

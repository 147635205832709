import React, { useCallback, useState } from "react";
import { table_price_function } from "../../../../../../data_system/data_functions/table_price_function/table_price_function";
import { only_number } from "../../../../../../data_system/validation_check/validation_check";
import { WhitePlus1x } from "../../../../../icons/icons";
import {
  h_px10rate,
  h_px12rate,
  h_px14rate,
  h_px25rate,
  h_px28rate,
  h_px80rate,
  w_px11rate,
  w_px215rate,
  w_px25rate,
  w_px26rate,
  w_px54rate,
  w_px6rate,
  w_px74rate,
  w_px8rate,
} from "../../../../../size";
import BlueBtn from "../../../../blue_btn/BlueBtn";
import ContentText from "../../../../content_text/ContentText";
import DetailTextarea from "../../../../detail_textarea/DetailTextarea";
import DetailTextInput from "../../../../detail_text_input/DetailTextInput";
import DivisionWhiteBlueLine from "../../../../division_white_blue_line/DivisionWhiteBlueLine";
import ListContent from "../../../../list_content/ListContent";
import ListName from "../../../../list_name/ListName";
import PinkBtn from "../../../../pink_btn/PinkBtn";
import SubTitle from "../../../../sub_title/SubTitle";
import UserName from "../../../../user_name/UserName";
import WhiteBtn from "../../../../white_btn/WhiteBtn";
import { table_date_function } from "../../../../../../data_system/data_functions/table_date_function/table_date_function";
import moment from "moment";

const SubscribePaymentDetailComponent = ({ subscribePaymentDetail }) => {
  console.log(subscribePaymentDetail);

  const totalSubscriptionEndDate = moment
    .utc(subscribePaymentDetail.User.totalSubscriptionEndDate)
    .clone()
    .toDate();

  const totalSubscriptionStartDate = moment
    .utc(subscribePaymentDetail.User.totalSubscriptionStartDate)
    .clone()
    .toDate();

  return (
    <div>
      <div
        style={{
          paddingTop: h_px25rate,
          paddingLeft: w_px25rate,
        }}
      >
        <div
          style={{
            marginBottom: h_px14rate,
          }}
        >
          <SubTitle>회원 정보</SubTitle>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>이름</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>{subscribePaymentDetail.User.name}</ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>이메일</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>{subscribePaymentDetail.User.email}</ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>연락처</ListName>
          <div
            style={{
              display: "flex",
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>{subscribePaymentDetail.User.phoneNumber}</ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>숙소 개수</ListName>
          <div
            style={{
              display: "flex",
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>
              {subscribePaymentDetail.User.maxGuideNumber}
            </ContentText>
          </div>
        </div>
        <div
          style={{
            marginBottom: h_px12rate,
            alignItems: "center",
          }}
        >
          <DivisionWhiteBlueLine></DivisionWhiteBlueLine>
        </div>
        <div
          style={{
            marginBottom: h_px14rate,
          }}
        >
          <SubTitle>결제 정보</SubTitle>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>당월 결제일</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>
              {table_date_function(
                subscribePaymentDetail.User.totalSubscriptionEndDate
              )}
            </ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>결제 이용기간</ListName>
          <div
            style={{
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>
              {table_date_function(
                subscribePaymentDetail.User.totalSubscriptionStartDate
              )}{" "}
              ~{" "}
              {table_date_function(
                subscribePaymentDetail.User.totalSubscriptionEndDate
              )}
            </ContentText>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: h_px12rate,
          }}
        >
          <ListName>결제 금액</ListName>
          <div
            style={{
              display: "flex",
              marginLeft: w_px26rate,
            }}
          >
            <ContentText>
              {subscribePaymentDetail.User.subscriptionPrice}
            </ContentText>
          </div>
        </div>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <WhiteBtn onClick={() => {}}>저장</WhiteBtn>
          <div
            style={{
              marginLeft: w_px8rate,
            }}
          >
            <PinkBtn onClick={() => {}}>환불처리</PinkBtn>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default React.memo(SubscribePaymentDetailComponent);

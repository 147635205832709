import {
  w_px161rate,
  w_px162rate,
  w_px214rate,
  w_px215rate,
} from "../../design_system/size";
import {
  HEADER_TITLE,
  NAME,
  NUMBER,
  PRICE,
  STRING,
  SUBSCRIBE_CATEGORY,
} from "../data_type/data_type";

export const subscribe_payment_structureInfo = [
  {
    id: 0,
    close_width: w_px214rate,
    open_width: w_px161rate,
  },
  {
    id: 1,
    close_width: w_px214rate,
    open_width: w_px161rate,
  },
  {
    id: 2,
    close_width: w_px215rate,
    open_width: w_px161rate,
  },
  {
    id: 3,
    close_width: w_px215rate,
    open_width: w_px161rate,
  },
  {
    id: 4,
    close_width: w_px215rate,
    open_width: w_px162rate,
  },
  {
    id: 5,
    close_width: w_px215rate,
    open_width: w_px162rate,
  },
];

export const subscribe_payment_headerTitle = [
  {
    type: HEADER_TITLE,
    data: "이름",
  },
  {
    type: HEADER_TITLE,
    data: "이메일 주소",
  },
  {
    type: HEADER_TITLE,
    data: "휴대폰 번호",
  },
  {
    type: HEADER_TITLE,
    data: "숙소 개수",
  },
  {
    type: HEADER_TITLE,
    data: "구독 종류",
  },
  {
    type: HEADER_TITLE,
    data: "당월 결제금액",
  },
];

export const subscribe_payment_bodyContents = [
  {
    id: 0,
    data: [
      {
        type: NAME,
        data: "나르는갱얼쥐",
      },
      {
        type: STRING,
        data: "tjdwls452@naver.com",
      },
      {
        type: STRING,
        data: "010-1111-2222",
      },
      {
        type: NUMBER,
        data: 2,
      },
      {
        type: SUBSCRIBE_CATEGORY,
        data: 0,
      },
      {
        type: PRICE,
        data: 0,
      },
    ],
  },
];

import React, { useEffect, useState, useCallback, useContext } from "react";
import {
  administrator_bodyContents,
  administrator_headerTitle,
  administrator_structureInfo,
} from "../../data_system/administrator_data/administrator_data";
import AdminTable from "../../design_system/components/admin_table/AdminTable";
import { ADMINISTRATOR_DETAIL_COMPONENT } from "../../data_system/detail_data_type/detail_data_type";
import { DETAIL_COMMON_FORM } from "../../design_system/components/admin_table/detail/forms/detail_form_type/detail_form_type";
import { ADMINISTRATOR_DETAIL_REGISTER } from "../../data_system/register_data_type/register_data_type";
import LongDropdown from "../../design_system/components/long_dropdown/LongDropdown";
import {
  h_px17rate,
  h_px22rate,
  h_px30rate,
  w_px10rate,
  w_px12rate,
  w_px4rate,
  w_px5rate,
  w_px60rate,
} from "../../design_system/size";
import {
  deleteAdministratorByAdministratorId,
  getAdministratorsByPageWithCondition,
} from "../../communication_system/axios_apis/axios_apis";
import { CHECK_BOX, NAME, STRING } from "../../data_system/data_type/data_type";
import {
  rgb_255_255_255,
  rgb_35_40_45,
  rgb_66_139_247,
} from "../../design_system/colors";
import SearchBar from "../../design_system/components/searchbar/SearchBar";
import styled from "styled-components";
import { BodyContentsContext } from "../../data_system/contexts/body_contents_context/body_contents_context";
import produce from "immer";

const Administrator = () => {
  const { bodyContents, setBodyContents } = useContext(BodyContentsContext);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [totalAdministratorsNumber, setTotalAdministratorsNumber] = useState(0);
  const [loading, setLoading] = useState(true);

  const [choiceRowIndex, setChoiceRowIndex] = useState(null);
  const [itemOpen, setItemOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);

  const [userDetailDropdownItem, setUserDetailDropdownItem] = useState({
    id: 0,
    title: "이름",
    selected: false,
    key: "name",
    value: 1,
  });

  const [userDetailList, setUserDetailList] = useState([
    {
      id: 0,
      title: "이름",
      selected: false,
      key: "name",
      value: 1,
    },
    {
      id: 1,
      title: "이메일 주소",
      selected: false,
      key: "email",
      value: 2,
    },
    {
      id: 2,
      title: "휴대폰 번호",
      selected: false,
      key: "phone_number",
      value: 3,
    },
  ]);

  const [page, setPage] = useState(1);

  const userDetailResetThenSet = useCallback((item) => {
    setUserDetailDropdownItem(item);
  }, []);

  const onChangeSearchText = useCallback((e) => {
    setSearchText(e.target.value);
  }, []);

  const findCheckedRowIds = () => {
    // 체크 박스가 1인 행들을 필터링하고, 해당 행들의 id를 추출합니다.
    const checkedIds = bodyContents
      .filter((row) => parseInt(row["data"][0].data) === 1)
      .map((row) => row.id);

    return checkedIds;
  };

  const onSearch = useCallback(async () => {
    const response = await getAdministratorsByPageWithCondition(
      page,
      userDetailDropdownItem.value,
      searchText
    );
    console.log(response);
    let tempBodyContents = [];
    for (let i = 0; i < response.data.administrators.length; i++) {
      tempBodyContents.push({
        id: response.data.administrators[i].id,
        data: [
          {
            type: CHECK_BOX,
            data: 0,
          },
          {
            type: NAME,
            data: response.data.administrators[i].name,
          },
          {
            type: STRING,
            data: response.data.administrators[i].email,
          },
          {
            type: STRING,
            data: response.data.administrators[i].phoneNumber,
          },
        ],
      });
    }
    setBodyContents(tempBodyContents);
    setTotalPages(response.data.totalPages);
    setTotalAdministratorsNumber(response.data.totalAdministratorsNumber);
  }, [page, userDetailDropdownItem.value, searchText]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await getAdministratorsByPageWithCondition(
        page,
        userDetailDropdownItem.value,
        searchText
      );
      console.log(response);
      let tempBodyContents = [];
      for (let i = 0; i < response.data.administrators.length; i++) {
        tempBodyContents.push({
          id: response.data.administrators[i].id,
          data: [
            {
              type: CHECK_BOX,
              data: 0,
            },
            {
              type: NAME,
              data: response.data.administrators[i].name,
            },
            {
              type: STRING,
              data: response.data.administrators[i].email,
            },
            {
              type: STRING,
              data: response.data.administrators[i].phoneNumber,
            },
          ],
        });
      }
      setBodyContents(tempBodyContents);
      setTotalPages(response.data.totalPages);
      setTotalAdministratorsNumber(response.data.totalAdministratorsNumber);
      setLoading(false);
    }

    fetchData();
  }, [page]);

  if (loading) return <div>loading...</div>;

  return (
    <>
      <>
        <SearchSection>
          <LongDropdown
            list={userDetailList}
            resetThenSet={userDetailResetThenSet}
            index={0}
          ></LongDropdown>
          <div
            style={{
              marginLeft: w_px4rate,
            }}
          >
            <SearchBar
              searchText={searchText}
              onChangeSearchText={onChangeSearchText}
              onSearch={onSearch}
            ></SearchBar>
          </div>
        </SearchSection>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: h_px17rate,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <MiddleSectionBlackText>전체 운영자</MiddleSectionBlackText>
            <div
              style={{
                marginLeft: w_px5rate,
                display: "flex",
                alignItems: "center",
              }}
            >
              <MiddleSectionBlueText>
                {totalAdministratorsNumber}
              </MiddleSectionBlueText>
              <MiddleSectionBlackText>명</MiddleSectionBlackText>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <RegisterBtn
              onClick={() => {
                setChoiceRowIndex(null);
                if (!itemOpen && !registerOpen) {
                  setItemOpen(true);
                  setRegisterOpen(true);
                } else if (itemOpen && registerOpen) {
                  setItemOpen(false);
                  setRegisterOpen(false);
                } else if (itemOpen && !registerOpen) {
                  setRegisterOpen(true);
                }
              }}
            >
              등록
            </RegisterBtn>
            <div
              style={{
                marginLeft: w_px10rate,
              }}
            >
              <DeleteBtn
                onClick={async () => {
                  const checkedIds = findCheckedRowIds();
                  console.log(checkedIds[0]);

                  const response = await deleteAdministratorByAdministratorId(
                    checkedIds[0]
                  );

                  if (response.status === 200) {
                    setBodyContents(
                      produce((draft) => {
                        return draft.filter(
                          (row) => parseInt(row["data"][0].data) !== 1
                        );
                      })
                    );
                  }
                }}
              >
                삭제
              </DeleteBtn>
            </div>
          </div>
        </div>
      </>
      <AdminTable
        structureInfo={administrator_structureInfo}
        headerTitle={administrator_headerTitle}
        bodyContents={bodyContents}
        DETAIL_DATA_TYPE={ADMINISTRATOR_DETAIL_COMPONENT}
        DETAIL_FORM_TYPE={DETAIL_COMMON_FORM}
        DETAIL_DATA_REGISTER={ADMINISTRATOR_DETAIL_REGISTER}
        detail_disable={false}
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        setBodyContents={setBodyContents}
        choiceRowIndex={choiceRowIndex}
        setChoiceRowIndex={setChoiceRowIndex}
        itemOpen={itemOpen}
        setItemOpen={setItemOpen}
        registerOpen={registerOpen}
        setRegisterOpen={setRegisterOpen}
      ></AdminTable>
    </>
  );
};

export default React.memo(Administrator);

const SearchSection = styled.div`
  position: relative;
  display: flex;
  margin-bottom: ${h_px22rate}px;
  z-index: 999;
`;

const MiddleSectionBlackText = styled.div`
  font-size: ${w_px12rate}px;
  color: ${rgb_35_40_45};
  font-family: noto_sans_kr_bold;
`;

const MiddleSectionBlueText = styled.div`
  font-size: ${w_px12rate}px;
  color: ${rgb_66_139_247};
  font-family: noto_sans_kr_bold;
`;

const RegisterBtn = styled.button`
  width: ${w_px60rate}px;
  height: ${h_px30rate}px;
  border: 1px solid ${rgb_66_139_247};
  background-color: ${rgb_66_139_247};
  color: ${rgb_255_255_255};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${w_px12rate}px;
  font-family: noto_sans_kr_bold;
`;

const DeleteBtn = styled.button`
  width: ${w_px60rate}px;
  height: ${h_px30rate}px;
  border: 1px solid ${rgb_66_139_247};
  background-color: ${rgb_255_255_255};
  color: ${rgb_66_139_247};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${w_px12rate}px;
  font-family: noto_sans_kr_bold;
`;
